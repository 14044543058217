import * as tslib_1 from "tslib";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { concat, Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, share, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { idb } from '../storage/indexedDB';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { AppVariables } from '../interfaces/app-variables';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../interfaces/app-variables";
import * as i3 from "ngx-toastr";
import * as i4 from "./auth.service";
var SyncService = /** @class */ (function () {
    function SyncService(http, app, toast, _auth) {
        this.http = http;
        this.app = app;
        this.toast = toast;
        this._auth = _auth;
    }
    SyncService.prototype.registerBgSync = function () {
        navigator.serviceWorker.ready.then(function (registration) {
            registration.sync.register('sync-tasks');
        });
    };
    SyncService.prototype.saved = function (task, showNotif) {
        if (showNotif === void 0) { showNotif = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, idb.sync_task.put(task).then(function () {
                        if (showNotif)
                            _this.showNotif();
                        _this.sync();
                        // if (environment.production) {
                        //   this.registerBgSync();
                        // } else {
                        // }
                        // return data;
                    })];
            });
        });
    };
    // tryPostPayload(url: string, payload: any, method: 'post' | 'patch' = 'post', param?: any): Observable<any> {
    //   const uri = environment.api + url;
    //   let params = new HttpParams({ fromObject: param || {}, encoder: new HttpUrlEncodingCodec() });
    //   return this.http[method]<any>(uri, payload, { params }).pipe(
    //     // timeout(10000),
    //     // retry(2),
    //     catchError((err: HttpErrorResponse) => this.handleError(err, url, payload, method)),
    //     share(),
    //   );
    // }
    SyncService.prototype.handleError = function (err) {
        if (this.offlineOrBadConnection(err)) {
            idb.sync_task
                .filter(function (_a) {
                var processed = _a.processed;
                return processed;
            })
                .toArray()
                .then(function (syncTasks) {
                for (var _i = 0, syncTasks_1 = syncTasks; _i < syncTasks_1.length; _i++) {
                    var task = syncTasks_1[_i];
                    task.response_error = err;
                    task.error = true;
                    task.processed = false;
                    if (err instanceof HttpErrorResponse) {
                        var ok = err.ok, name_1 = err.name, message = err.message;
                        task.response_error = { success: ok, name: name_1, message: message };
                    }
                    idb.sync_task.put(task);
                }
            });
            return throwError(err);
        }
        else {
            console.log('A backend error occurred.', err);
            return throwError(err);
        }
    };
    SyncService.prototype.offlineOrBadConnection = function (err) {
        return err instanceof TimeoutError || err.error instanceof ErrorEvent || !this.app.connected;
    };
    SyncService.prototype.sync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var syncTasks, requests_1, all$;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.app.connected) return [3 /*break*/, 2];
                        return [4 /*yield*/, idb.sync_task.filter(function (_a) {
                                var processed = _a.processed;
                                return !processed;
                            }).toArray()];
                    case 1:
                        syncTasks = _a.sent();
                        requests_1 = [];
                        this.app.manual_sync_processing = true;
                        syncTasks.forEach(function (task) {
                            var method = task.method, url = task.url, payload = task.payload;
                            task.processed = true;
                            idb.sync_task.put(task);
                            var obs$ = _this.http[method](url, payload).pipe(map(function (result) { return ({ result: result, task: task }); }), catchError(function (error) {
                                return _this.handleError(error);
                            }));
                            requests_1.push(obs$);
                        });
                        all$ = concat.apply(void 0, requests_1).pipe(share());
                        all$
                            .subscribe(function (response) {
                            var task = response.task, result = response.result;
                            var success = result.success, code = result.code;
                            if (success || code === 11001) {
                                // Success OR duplicate entry or expired
                                idb.sync_task.delete(task.id);
                            }
                            else {
                                task.response_error = result;
                                task.error = true;
                                task.processed = false;
                                idb.sync_task.put(task);
                            }
                        }, function (err) {
                            console.log(err);
                        })
                            .add(function () {
                            _this.app.manual_sync_processing = false;
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SyncService.prototype.showNotif = function () {
        var toastType = this.app.connected ? 'success' : 'warning';
        this.toast[toastType]('Due to slow or absence of internet, your data will be stored locally and will be sync when internet connection is restored', 'Data saved offline!', { timeOut: 1500, progressAnimation: 'increasing' });
    };
    SyncService.prototype.init_inventories = function (branch, fromLogin) {
        if (fromLogin === void 0) { fromLogin = false; }
        if (this.app.connected) {
            var api = environment.api;
            this.http.get(api + '/inventory?view=true&branch=' + branch).subscribe(function (rs) {
                if (rs.success) {
                    // if (fromLogin && this.app.connected) idb.inventories.clear();
                    idb.inventories.clear().then(function () {
                        idb.inventories.bulkPut(rs.data.inventories);
                    });
                }
            });
            this.http.get(api + '/inventory/serials?view=true&status=available&branch=' + branch).subscribe(function (rs) {
                if (rs.success) {
                    // if (fromLogin && this.app.connected) idb.serials.clear();
                    idb.serials.clear().then(function () {
                        idb.serials.bulkPut(rs.data.inventories);
                    });
                }
            });
        }
    };
    SyncService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SyncService_Factory() { return new SyncService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppVariables), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i4.AuthService)); }, token: SyncService, providedIn: "root" });
    return SyncService;
}());
export { SyncService };
