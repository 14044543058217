/// <reference types="web-bluetooth" />

import { ElementRef, Injectable, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { AppVariables } from '../interfaces/app-variables';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor(private appVar: AppVariables, private readonly toastSvc: ToastrService, private meta: Meta) {}

  private connect() {
    return new Promise((resolve, reject) => {
      if ('bluetooth' in navigator) {
        const printer_service = '000018f0-0000-1000-8000-00805f9b34fb';
        const printer_characteristic = '00002af1-0000-1000-8000-00805f9b34fb';

        if (!this.appVar.printer_characteristic) {
          const services = [printer_service];
          navigator.bluetooth
            .requestDevice({ filters: [{ services }] })
            .then(dvc => {
              console.log('connecting to device...');
              dvc.addEventListener('gattserverdisconnected', onDisconnected);
              return dvc.gatt.connect();
            })
            .then(server => {
              console.log('Getting primary service...');
              return server.getPrimaryService(printer_service);
            })
            .then(service => {
              // Step 4: get the Characteristic
              console.log('Getting Characteristic...');
              return service.getCharacteristic(printer_characteristic);
            })
            .then(characteristic => {
              // console.log('Getting characteristic...');
              this.appVar.printer_connected = characteristic.service.device.gatt.connected;
              this.appVar.printer_characteristic = characteristic;

              resolve(characteristic);
              this.toastSvc.info('printer connected', 'Success!');
              console.log('printer connected!');
              // this.sendTextData();
            })
            .catch(error => {
              // console.log('error => ', error);
              this.toastSvc.info('Unable to connect to printer', 'Please try again!');
              // reject();
            });
          // .finally(() => {
          //   console.log('finally');
          // });

          const onDisconnected = event => {
            const device = event.target;
            this.appVar.printer_connected = false;
            this.appVar.printer_characteristic = null;
            device.gatt.disconnect();
            this.toastSvc.warning(`Device ${device.name} is disconnected`);
          };
        } else {
          resolve(this.appVar.printer_characteristic);
        }
      } else {
        this.toastSvc.warning('Unable to connect to printer', 'Bluetooth not supported');
        // reject();
      }
    });
  }

  onConnect() {
    // console.log(window.devicePixelRatio);
    // this.toastSvc.info('width:' + screen.width);
    // return this.connect();
    return this.connect().then(() => this.sendTextData());
  }

  onPrintTest() {
    return this.connect();
  }

  send2printer(el?: ElementRef<HTMLCanvasElement>): Promise<any> {
    return new Promise((resolve, reject) => {
      this.connect().then(async (printer: any) => {
        this.appVar.printing = true;
        el.nativeElement.scrollTo(0, 0);
        el.nativeElement.style.overflow = 'visible';
        el.nativeElement.style.width = '380px';
        el.nativeElement.style.fontSize = '18px';
        // this.meta.updateTag({ name: 'viewport', content: 'width=1280px' });
        const options = {
          backgroundColor: null,
          height: el.nativeElement.scrollHeight + 50,
          width: 400,
          scale: 1,
        };
        const canvas = await html2canvas(el.nativeElement, options);
        // this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
        el.nativeElement.style.overflow = 'auto';
        el.nativeElement.style.width = '';
        el.nativeElement.style.fontSize = '';

        this.getPrintData(canvas).then((data: Uint8Array) => {
          const dataCount = data.length;
          let index = 0;
          const sendDataByBatch = () => {
            if (index + 512 < dataCount) {
              const text = data.slice(index, index + 512);
              printer
                .writeValue(text)
                .then(() => {
                  index += 512;
                  sendDataByBatch();
                })
                .catch((err: any) => reject(err));
            } else {
              if (index < dataCount) {
                const text = data.slice(index, dataCount);
                printer.writeValue(text).then(() => resolve(''));
                // resolve('');
              } else {
                resolve('');
              }
            }
          };

          sendDataByBatch();
        });
      });
    });
  }

  private getPrintData(canvas: HTMLCanvasElement) {
    return new Promise(resolve => {
      let ctx = canvas.getContext('2d');
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

      const getDarkPixel = (x: number, y: number) => {
        // Return the pixels that will be printed black
        const pixel = (canvas.width * y + x) * 4;
        const red = imageData[pixel];
        const green = imageData[pixel + 1];
        const blue = imageData[pixel + 2];
        return red + green + blue > 0 ? 1 : 0;
      };

      let printData = new Uint8Array((canvas.width / 8) * canvas.height + 8);

      let offset = 0;
      // Set the header bytes for printing the image
      printData[0] = 29; // Print raster bitmap
      printData[1] = 118; // Print raster bitmap
      printData[2] = 48; // Print raster bitmap
      printData[3] = 0; // Normal 203.2 DPI
      printData[4] = canvas.width / 8; // Number of horizontal data bits (LSB)
      printData[5] = 0; // Number of horizontal data bits (MSB)
      printData[6] = canvas.height % 256; // Number of vertical data bits (LSB)
      printData[7] = canvas.height / 256; // Number of vertical data bits (MSB)
      offset = 7;

      // Loop through image rows in bytes
      for (let i = 0; i < canvas.height; ++i) {
        for (let k = 0; k < canvas.width / 8; ++k) {
          const k8 = k * 8;
          printData[++offset] =
            getDarkPixel(k8 + 0, i) * 128 +
            getDarkPixel(k8 + 1, i) * 64 +
            getDarkPixel(k8 + 2, i) * 32 +
            getDarkPixel(k8 + 3, i) * 16 +
            getDarkPixel(k8 + 4, i) * 8 +
            getDarkPixel(k8 + 5, i) * 4 +
            getDarkPixel(k8 + 6, i) * 2 +
            getDarkPixel(k8 + 7, i);
        }
      }

      resolve(printData);
    });
  }

  sendTextData() {
    // Get the bytes for the text
    let encoder = new TextEncoder();
    // Add line feed + carriage return chars to text
    let text = encoder.encode('This is a test print' + '\u000A\u000D'.repeat(3));
    this.appVar.printer_characteristic.writeValue(text).then(() => {
      console.log('Write done.');
    });
  }
}
