import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineEditorModule } from '@qontu/ngx-inline-editor';
import { NgSelectModule } from '@ng-select/ng-select';

// Import 3rd party components
import {
  CollapseModule,
  ModalModule,
  TabsModule,
  PaginationModule,
  BsDropdownModule,
  BsDatepickerModule,
  ProgressbarModule,
  AccordionModule,
  TooltipModule,
} from 'ngx-bootstrap';

import { FlashMessagesModule } from 'angular2-flash-messages';
import { FormsModule } from '@angular/forms';
import { APP_SPINNERS } from './core/layouts/templates';
import { APP_PIPES } from './core/pipe/shared.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    FlashMessagesModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    InlineEditorModule,
    NgSelectModule,
    ProgressbarModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
  ],
  declarations: [...APP_SPINNERS, ...APP_PIPES],
  exports: [
    BsDropdownModule,
    TabsModule,
    FlashMessagesModule,
    FormsModule,
    CollapseModule,
    ModalModule,
    BsDatepickerModule,
    InlineEditorModule,
    NgSelectModule,
    PaginationModule,
    ProgressbarModule,
    AccordionModule,
    TooltipModule,
    APP_SPINNERS,
    APP_PIPES,
  ],
})
export class SharedModule {}
