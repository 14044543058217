import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { concat, Observable, of, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout, share, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { idb } from '../storage/indexedDB';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { AppVariables } from '../interfaces/app-variables';
import { SyncTask } from '../interfaces/sync-task';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(private http: HttpClient, private app: AppVariables, public toast: ToastrService, private _auth: AuthService) {}

  registerBgSync() {
    navigator.serviceWorker.ready.then((registration: any) => {
      registration.sync.register('sync-tasks');
    });
  }

  async saved(task: SyncTask, showNotif = true): Promise<any> {
    return idb.sync_task.put(task).then(() => {
      if (showNotif) this.showNotif();
      this.sync();

      // if (environment.production) {
      //   this.registerBgSync();
      // } else {
      // }
      // return data;
    });
  }

  // tryPostPayload(url: string, payload: any, method: 'post' | 'patch' = 'post', param?: any): Observable<any> {
  //   const uri = environment.api + url;
  //   let params = new HttpParams({ fromObject: param || {}, encoder: new HttpUrlEncodingCodec() });

  //   return this.http[method]<any>(uri, payload, { params }).pipe(
  //     // timeout(10000),
  //     // retry(2),
  //     catchError((err: HttpErrorResponse) => this.handleError(err, url, payload, method)),
  //     share(),
  //   );
  // }

  private handleError(err: HttpErrorResponse): Observable<any> {
    if (this.offlineOrBadConnection(err)) {
      idb.sync_task
        .filter(({ processed }) => processed)
        .toArray()
        .then((syncTasks: any) => {
          for (const task of syncTasks) {
            task.response_error = err;
            task.error = true;
            task.processed = false;
            if (err instanceof HttpErrorResponse) {
              const { ok, name, message } = err;
              task.response_error = { success: ok, name, message };
            }
            idb.sync_task.put(task);
          }
        });

      return throwError(err);
    } else {
      console.log('A backend error occurred.', err);
      return throwError(err);
    }
  }

  private offlineOrBadConnection(err: HttpErrorResponse): boolean {
    return err instanceof TimeoutError || err.error instanceof ErrorEvent || !this.app.connected;
  }

  async sync() {
    if (this.app.connected) {
      const syncTasks = await idb.sync_task.filter(({ processed }) => !processed).toArray();
      const requests: Observable<any>[] = [];

      this.app.manual_sync_processing = true;

      syncTasks.forEach((task: any) => {
        const { method, url, payload } = task;
        task.processed = true;
        idb.sync_task.put(task);
        const obs$ = this.http[method]<any>(url, payload).pipe(
          map(result => ({ result, task })),
          catchError((error: HttpErrorResponse) => {
            return this.handleError(error);
          }),
        );

        requests.push(obs$);
      });

      const all$ = concat(...requests).pipe(share());

      all$
        .subscribe(
          response => {
            const { task, result } = response;
            const { success, code } = result;
            if (success || code === 11001) {
              // Success OR duplicate entry or expired
              idb.sync_task.delete(task.id);
            } else {
              task.response_error = result;
              task.error = true;
              task.processed = false;
              idb.sync_task.put(task);
            }
          },
          err => {
            console.log(err);
          },
        )
        .add(() => {
          this.app.manual_sync_processing = false;
        });
    }
  }

  showNotif() {
    const toastType = this.app.connected ? 'success' : 'warning';
    this.toast[toastType](
      'Due to slow or absence of internet, your data will be stored locally and will be sync when internet connection is restored',
      'Data saved offline!',
      { timeOut: 1500, progressAnimation: 'increasing' },
    );
  }

  init_inventories(branch: string, fromLogin: boolean = false) {
    if (this.app.connected) {
      const api = environment.api;
      this.http.get<any>(api + '/inventory?view=true&branch=' + branch).subscribe(rs => {
        if (rs.success) {
          // if (fromLogin && this.app.connected) idb.inventories.clear();
          idb.inventories.clear().then(() => {
            idb.inventories.bulkPut(rs.data.inventories);
          });
        }
      });

      this.http.get<any>(api + '/inventory/serials?view=true&status=available&branch=' + branch).subscribe(rs => {
        if (rs.success) {
          // if (fromLogin && this.app.connected) idb.serials.clear();
          idb.serials.clear().then(() => {
            idb.serials.bulkPut(rs.data.inventories);
          });
        }
      });
    }
  }
}
