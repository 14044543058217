
    <li *ngIf="!isDropdown(); else dropdown" [ngClass]="hasClass() ? 'nav-item ' + item.class : 'nav-item'">
      <app-sidebar-nav-link [link]="item" *ngIf="isProtected()"></app-sidebar-nav-link>
    </li>
    <ng-template #dropdown>
      <li
        *ngIf="isProtected()"
        [ngClass]="hasClass() ? 'nav-item nav-dropdown ' + item.class : 'nav-item nav-dropdown'"
        [class.open]="isActive()"
        routerLinkActive="open"
        appNavDropdown>
        <app-sidebar-nav-dropdown [link]="item"></app-sidebar-nav-dropdown>
      </li>
    </ng-template>
  