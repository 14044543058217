import * as tslib_1 from "tslib";
/// <reference types="web-bluetooth" />
import { ElementRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { AppVariables } from '../interfaces/app-variables';
import * as i0 from "@angular/core";
import * as i1 from "../interfaces/app-variables";
import * as i2 from "ngx-toastr";
import * as i3 from "@angular/platform-browser";
var PrintService = /** @class */ (function () {
    function PrintService(appVar, toastSvc, meta) {
        this.appVar = appVar;
        this.toastSvc = toastSvc;
        this.meta = meta;
    }
    PrintService.prototype.connect = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if ('bluetooth' in navigator) {
                var printer_service_1 = '000018f0-0000-1000-8000-00805f9b34fb';
                var printer_characteristic_1 = '00002af1-0000-1000-8000-00805f9b34fb';
                if (!_this.appVar.printer_characteristic) {
                    var services = [printer_service_1];
                    navigator.bluetooth
                        .requestDevice({ filters: [{ services: services }] })
                        .then(function (dvc) {
                        console.log('connecting to device...');
                        dvc.addEventListener('gattserverdisconnected', onDisconnected_1);
                        return dvc.gatt.connect();
                    })
                        .then(function (server) {
                        console.log('Getting primary service...');
                        return server.getPrimaryService(printer_service_1);
                    })
                        .then(function (service) {
                        // Step 4: get the Characteristic
                        console.log('Getting Characteristic...');
                        return service.getCharacteristic(printer_characteristic_1);
                    })
                        .then(function (characteristic) {
                        // console.log('Getting characteristic...');
                        _this.appVar.printer_connected = characteristic.service.device.gatt.connected;
                        _this.appVar.printer_characteristic = characteristic;
                        resolve(characteristic);
                        _this.toastSvc.info('printer connected', 'Success!');
                        console.log('printer connected!');
                        // this.sendTextData();
                    })
                        .catch(function (error) {
                        // console.log('error => ', error);
                        _this.toastSvc.info('Unable to connect to printer', 'Please try again!');
                        // reject();
                    });
                    // .finally(() => {
                    //   console.log('finally');
                    // });
                    var onDisconnected_1 = function (event) {
                        var device = event.target;
                        _this.appVar.printer_connected = false;
                        _this.appVar.printer_characteristic = null;
                        device.gatt.disconnect();
                        _this.toastSvc.warning("Device " + device.name + " is disconnected");
                    };
                }
                else {
                    resolve(_this.appVar.printer_characteristic);
                }
            }
            else {
                _this.toastSvc.warning('Unable to connect to printer', 'Bluetooth not supported');
                // reject();
            }
        });
    };
    PrintService.prototype.onConnect = function () {
        var _this = this;
        // console.log(window.devicePixelRatio);
        // this.toastSvc.info('width:' + screen.width);
        // return this.connect();
        return this.connect().then(function () { return _this.sendTextData(); });
    };
    PrintService.prototype.onPrintTest = function () {
        return this.connect();
    };
    PrintService.prototype.send2printer = function (el) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.connect().then(function (printer) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var options, canvas;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.appVar.printing = true;
                            el.nativeElement.scrollTo(0, 0);
                            el.nativeElement.style.overflow = 'visible';
                            el.nativeElement.style.width = '380px';
                            el.nativeElement.style.fontSize = '18px';
                            options = {
                                backgroundColor: null,
                                height: el.nativeElement.scrollHeight + 50,
                                width: 400,
                                scale: 1,
                            };
                            return [4 /*yield*/, html2canvas(el.nativeElement, options)];
                        case 1:
                            canvas = _a.sent();
                            // this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
                            el.nativeElement.style.overflow = 'auto';
                            el.nativeElement.style.width = '';
                            el.nativeElement.style.fontSize = '';
                            this.getPrintData(canvas).then(function (data) {
                                var dataCount = data.length;
                                var index = 0;
                                var sendDataByBatch = function () {
                                    if (index + 512 < dataCount) {
                                        var text = data.slice(index, index + 512);
                                        printer
                                            .writeValue(text)
                                            .then(function () {
                                            index += 512;
                                            sendDataByBatch();
                                        })
                                            .catch(function (err) { return reject(err); });
                                    }
                                    else {
                                        if (index < dataCount) {
                                            var text = data.slice(index, dataCount);
                                            printer.writeValue(text).then(function () { return resolve(''); });
                                            // resolve('');
                                        }
                                        else {
                                            resolve('');
                                        }
                                    }
                                };
                                sendDataByBatch();
                            });
                            return [2 /*return*/];
                    }
                });
            }); });
        });
    };
    PrintService.prototype.getPrintData = function (canvas) {
        return new Promise(function (resolve) {
            var ctx = canvas.getContext('2d');
            var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
            var getDarkPixel = function (x, y) {
                // Return the pixels that will be printed black
                var pixel = (canvas.width * y + x) * 4;
                var red = imageData[pixel];
                var green = imageData[pixel + 1];
                var blue = imageData[pixel + 2];
                return red + green + blue > 0 ? 1 : 0;
            };
            var printData = new Uint8Array((canvas.width / 8) * canvas.height + 8);
            var offset = 0;
            // Set the header bytes for printing the image
            printData[0] = 29; // Print raster bitmap
            printData[1] = 118; // Print raster bitmap
            printData[2] = 48; // Print raster bitmap
            printData[3] = 0; // Normal 203.2 DPI
            printData[4] = canvas.width / 8; // Number of horizontal data bits (LSB)
            printData[5] = 0; // Number of horizontal data bits (MSB)
            printData[6] = canvas.height % 256; // Number of vertical data bits (LSB)
            printData[7] = canvas.height / 256; // Number of vertical data bits (MSB)
            offset = 7;
            // Loop through image rows in bytes
            for (var i = 0; i < canvas.height; ++i) {
                for (var k = 0; k < canvas.width / 8; ++k) {
                    var k8 = k * 8;
                    printData[++offset] =
                        getDarkPixel(k8 + 0, i) * 128 +
                            getDarkPixel(k8 + 1, i) * 64 +
                            getDarkPixel(k8 + 2, i) * 32 +
                            getDarkPixel(k8 + 3, i) * 16 +
                            getDarkPixel(k8 + 4, i) * 8 +
                            getDarkPixel(k8 + 5, i) * 4 +
                            getDarkPixel(k8 + 6, i) * 2 +
                            getDarkPixel(k8 + 7, i);
                }
            }
            resolve(printData);
        });
    };
    PrintService.prototype.sendTextData = function () {
        // Get the bytes for the text
        var encoder = new TextEncoder();
        // Add line feed + carriage return chars to text
        var text = encoder.encode('This is a test print' + '\u000A\u000D'.repeat(3));
        this.appVar.printer_characteristic.writeValue(text).then(function () {
            console.log('Write done.');
        });
    };
    PrintService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrintService_Factory() { return new PrintService(i0.ɵɵinject(i1.AppVariables), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.Meta)); }, token: PrintService, providedIn: "root" });
    return PrintService;
}());
export { PrintService };
