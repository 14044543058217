import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PincodeComponent } from './pincode/pincode.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ProductNumpadComponent } from './product-numpad/product-numpad.component';
import { BranchComponent } from './branch/branch.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { CustomerComponent } from './customer/customer.component';
import { SalemanComponent } from './saleman/saleman.component';

@NgModule({
  declarations: [PincodeComponent, ProductItemComponent, ProductSearchComponent, ProductNumpadComponent, BranchComponent, CustomerComponent, SalemanComponent],
  imports: [CommonModule, FormsModule, SharedModule],
  exports: [PincodeComponent, ProductItemComponent, ProductSearchComponent, ProductNumpadComponent, BranchComponent, CustomerComponent, SalemanComponent],
})
export class ComponentsModule {}
