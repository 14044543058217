import * as tslib_1 from "tslib";
import { ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
var ErrorsHandler = /** @class */ (function () {
    // toastr: any;
    function ErrorsHandler(injector) {
        this.injector = injector;
    }
    ErrorsHandler.prototype.handleError = function (error) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toastr, auth, message_1, message_2;
            return tslib_1.__generator(this, function (_a) {
                toastr = this.injector.get(ToastrService);
                auth = this.injector.get(AuthService);
                if (error instanceof HttpErrorResponse) {
                    auth.spin_hide();
                    if (!navigator.onLine) {
                        setTimeout(function () {
                            toastr.error('There must be something wrong with your internet connection, It might that data will not be saved', 'No Internet Connection');
                        }, 300);
                    }
                    else {
                        // console.error('Server Error!', error);
                        if (error.status === 401) {
                            setTimeout(function () {
                                toastr.warning('Please try to re-login to gain access!', 'ACCESS EXPIRES!');
                            }, 100);
                        }
                        else if (error.status === 400) {
                            setTimeout(function () {
                                toastr.warning(error.error.message, 'Bad Request!');
                            }, 100);
                        }
                        else {
                            if (error.error.errors) {
                                message_1 = error.error.errors.error_info.message || 'Not Found';
                                setTimeout(function () {
                                    toastr.error(message_1, 'Server Response Failed!');
                                }, 100);
                            }
                            else {
                                message_2 = error.error.message || error.message;
                                // const title = error.statusText || 'Server Error Request';
                                setTimeout(function () {
                                    toastr.error(message_2, 'Server Response Failed!');
                                }, 100);
                            }
                        }
                    }
                }
                else {
                    // console.log(error instanceof VersionError);
                    toastr.error("There's a Reference Error on this client", 'Client Error');
                }
                console.error('Error Happens All the time... : ', error);
                return [2 /*return*/];
            });
        });
    };
    return ErrorsHandler;
}());
export { ErrorsHandler };
