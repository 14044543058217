import { Injector, Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { idb } from '../storage/indexedDB';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private user: any;
  constructor(private socket: Socket, private injector: Injector) {
    this.user = localStorage.getItem('user');
  }

  async syncRef() {
    this.socket.emit('syncRef');
  }

  async syncAll(iDB: any) {
    const toastr = this.injector.get(ToastrService);
    this.socket.on('connected', (data: any) => {
      Object.keys(data).map(async key => {
        await iDB[key].bulkPut(data[key]);
        toastr.info(`${key} reference has been sync...`);
      });

      toastr.success('All reference data has been successfully sync!', 'Server Sync...');
    });

    this.socket.on('sync', (data: any) => {
      const { operationType, ns, documentKey, fullDocument } = data;
      const table = ns.coll;
      const { _id } = documentKey;

      switch (operationType) {
        case 'delete':
          idb[table].delete(_id);
          break;
        default:
          idb[table].put(fullDocument);
          break;
      }
    });

    this.socket.on('syncInventory', (data: any) => {
      const loc_user = localStorage.getItem('user');

      try {
        if (loc_user.length != 0) {
          const { assign_branch } = JSON.parse(loc_user);
          const { branch } = data;

          if (assign_branch._id.toString() === branch._id.toString()) {
            idb.inventories.put(data);
          }
        }
      } catch (error) {
        console.log('syncing db...');
      }
    });

    this.socket.on('syncSerial', (data: any) => {
      const loc_user = localStorage.getItem('user');
      const { assign_branch } = JSON.parse(loc_user);
      const { branch } = data;

      if (assign_branch._id.toString() === branch._id.toString()) {
        idb.serials.put(data);
      }
    });

    this.socket.on('dashboard', (data: any) => {
      const { operationType, document } = data;

      if (operationType == 'delete') {
        idb.dashboard.delete(document._id);
      } else {
        idb.dashboard.put(document);
      }
    });

    this.socket.on('syncDashboard', (data: any) => {
      idb.dashboard.clear().then(() => idb.dashboard.bulkPut(data));
    });
  }
}
