import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { idb } from '../storage/indexedDB';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io";
var WebSocketService = /** @class */ (function () {
    function WebSocketService(socket, injector) {
        this.socket = socket;
        this.injector = injector;
        this.user = localStorage.getItem('user');
    }
    WebSocketService.prototype.syncRef = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.socket.emit('syncRef');
                return [2 /*return*/];
            });
        });
    };
    WebSocketService.prototype.syncAll = function (iDB) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toastr;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                toastr = this.injector.get(ToastrService);
                this.socket.on('connected', function (data) {
                    Object.keys(data).map(function (key) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, iDB[key].bulkPut(data[key])];
                                case 1:
                                    _a.sent();
                                    toastr.info(key + " reference has been sync...");
                                    return [2 /*return*/];
                            }
                        });
                    }); });
                    toastr.success('All reference data has been successfully sync!', 'Server Sync...');
                });
                this.socket.on('sync', function (data) {
                    var operationType = data.operationType, ns = data.ns, documentKey = data.documentKey, fullDocument = data.fullDocument;
                    var table = ns.coll;
                    var _id = documentKey._id;
                    switch (operationType) {
                        case 'delete':
                            idb[table].delete(_id);
                            break;
                        default:
                            idb[table].put(fullDocument);
                            break;
                    }
                });
                this.socket.on('syncInventory', function (data) {
                    var loc_user = localStorage.getItem('user');
                    try {
                        if (loc_user.length != 0) {
                            var assign_branch = JSON.parse(loc_user).assign_branch;
                            var branch = data.branch;
                            if (assign_branch._id.toString() === branch._id.toString()) {
                                idb.inventories.put(data);
                            }
                        }
                    }
                    catch (error) {
                        console.log('syncing db...');
                    }
                });
                this.socket.on('syncSerial', function (data) {
                    var loc_user = localStorage.getItem('user');
                    var assign_branch = JSON.parse(loc_user).assign_branch;
                    var branch = data.branch;
                    if (assign_branch._id.toString() === branch._id.toString()) {
                        idb.serials.put(data);
                    }
                });
                this.socket.on('dashboard', function (data) {
                    var operationType = data.operationType, document = data.document;
                    if (operationType == 'delete') {
                        idb.dashboard.delete(document._id);
                    }
                    else {
                        idb.dashboard.put(document);
                    }
                });
                this.socket.on('syncDashboard', function (data) {
                    idb.dashboard.clear().then(function () { return idb.dashboard.bulkPut(data); });
                });
                return [2 /*return*/];
            });
        });
    };
    WebSocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebSocketService_Factory() { return new WebSocketService(i0.ɵɵinject(i1.Socket), i0.ɵɵinject(i0.INJECTOR)); }, token: WebSocketService, providedIn: "root" });
    return WebSocketService;
}());
export { WebSocketService };
