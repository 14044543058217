import * as tslib_1 from "tslib";
import { DatePipe } from '@angular/common';
import { EventEmitter, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Unsubsribe } from '../../pipe/unsubscribe.class';
import { ReferenceService } from '../../services/reference.service';
var CustomerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CustomerComponent, _super);
    function CustomerComponent(refSvc) {
        var _this = _super.call(this) || this;
        _this.refSvc = refSvc;
        _this.closed = new EventEmitter();
        _this.host = environment.host;
        _this.customers = [];
        _this.filtered_customers = [];
        _this.custom_customer = false;
        return _this;
    }
    CustomerComponent.prototype.ngOnInit = function () {
        this.init_references();
    };
    CustomerComponent.prototype.init_references = function () {
        var _this = this;
        this.refSvc
            .idb_find_v2('business_partners')
            .pipe(takeUntil(this.unsub$))
            .subscribe(function (docs) {
            _this.customers = docs.filter(function (_a) {
                var category = _a.category, is_active = _a.is_active;
                return is_active && category === 'CUSTOMER';
            });
            _this.filtered_customers = _this.customers;
        });
    };
    CustomerComponent.prototype.onSearchCustomer = function (evt) {
        var keyCode = evt.keyCode;
        if ([13, 27, 46].includes(keyCode))
            evt.target.value = '';
        var search_key = evt.target.value;
        if (search_key.length === 0)
            return (this.filtered_customers = this.customers);
        this.filtered_customers = this.customers.filter(function (_a) {
            var company = _a.company;
            return company.toLowerCase().indexOf(search_key.toLowerCase()) > -1;
        });
    };
    CustomerComponent.prototype.onCustomerInput = function () {
        this.default.walked_in = true;
        var display_name = this.default.display_name;
        var last = display_name.substring(display_name.indexOf(' ') + 1);
        var first = display_name.substring(0, display_name.length - last.length - 1);
        this.default.fullname = { first: first, last: last };
        this.closed.emit();
    };
    CustomerComponent.prototype.onCustomerChanged = function (customer) {
        var code = customer.code, company = customer.company, bill_to = customer.bill_to, balance = customer.balance, email = customer.email, contact_no = customer.contact_no;
        this.default.display_name = company;
        this.default.walked_in = false;
        this.default.fullname = {
            first: code,
            last: company,
        };
        this.default.email = email;
        this.default.address = bill_to;
        this.default.contact_no = contact_no;
        this.closed.emit();
    };
    CustomerComponent.prototype.onWalkIn = function () {
        var birthdate = '2014/02/14';
        Object.assign(this.default, {
            avatar: 'uploads/avatar/default.png',
            walked_in: true,
            display_name: 'WALK-IN CUSTOMER',
            members_card: new Date().getTime() + '' + Math.floor(Math.random() * 99999),
            fullname: {
                first: 'WALK-IN',
                last: 'CUSTOMER',
            },
            email: 'email@customer.ph',
            date_of_birth: new DatePipe(birthdate).transform(birthdate, 'yyyy-MM-dd', '+800', 'en-PH'),
            contact_no: '+639176367777',
            address: 'NPA',
        });
        this.closed.emit();
    };
    return CustomerComponent;
}(Unsubsribe));
export { CustomerComponent };
