import Dexie from 'dexie';

export class AppworksPosDB extends Dexie {
  constructor() {
    super('AppworxPosDB');
    this.version(2).stores({
      dashboard: '_id, ref_id, branch._id, [ref_id+branch._id]',
      branches: '_id',
      products: '_id, barcode',
      inventories: '_id',
      serials: '_id',
      pricelist: '_id',
      sales: 'ref_id',
      users: '_id',
      logins: '_id, &username',
      warehouses: '_id',
      ref_payments: '_id',
      ref_banks: '_id',
      ref_installments: '_id',
      ref_categories: '_id',
      ref_channels: '_id',
      ref_groups: '_id',
      ref_brands: '_id',
      ref_lifecycles: '_id',
      ref_families: '_id',
      ref_types: '_id',
      ref_rewards: '_id',
      business_partners: '_id',
      members: '_id',
      vouchers: '_id',
      sync_task: 'id',
    });

    this.open().catch(e => {
      console.log(e);
      if (e.name === 'VersionError') {
        if (confirm(`A version mismatch error on the database occurred! Reload the page. Accept?`)) {
          // Refresh current webapp so that it starts working with newer DB schema.
          this.delete();
          window.location.reload();
        } else {
          // Will let user finish its work in this window and
          // block the other window from upgrading.
          return false;
        }
      }
    });
  }
}

export const idb: any = new AppworksPosDB();
