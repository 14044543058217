import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ModulesGuard implements CanActivate, CanActivateChild {
  constructor(private _toast: ToastrService, private _router: Router, private _authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this._authService.getLoggedUser();
    if (user.modules[next.data.guard]) {
      return true;
    } else {
      this._toast.error("You don't have permission to access the " + next.data.guard + ' module.');
      this._authService.logout();
      this._router.navigate(['/authenticate']);
      return false;
    }
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this._authService.getLoggedUser();
    if (next.data.protect) {
      if (user.modules[next.data.module]) {
        return true;
      } else {
        this._toast.error("You don't have permission to access the " + next.data.module + ' module.');
        return false;
      }
    } else {
      if (next.data.protectChild) {
        const permission: boolean = user.authorization[next.data.permission[0]][next.data.permission[1]];
        if (permission) {
          return true;
        } else {
          this._toast.error("You don't have permission to access the module.");
          this._authService.logout();
          this._router.navigate(['/authenticate']);
          return false;
        }
      } else {
        return true;
      }
    }
  }
}
