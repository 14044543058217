import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AppVariables } from '../interfaces/app-variables';
import { WebSocketService } from './web-socket.service';
import { OfflineService } from './offline.service';
import { idb } from '../storage/indexedDB';
import { environment } from '../../../environments/environment';
var AppSettingsService = /** @class */ (function () {
    function AppSettingsService(http, _app, offlineSvc, webSocketSvc) {
        this.http = http;
        this._app = _app;
        this.offlineSvc = offlineSvc;
        this.webSocketSvc = webSocketSvc;
        this.offlineSvc.initNetwork();
    }
    AppSettingsService.prototype.initializeApp = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.webSocketSvc.syncAll(idb)];
                    case 1:
                        _a.sent();
                        this._app.connected = this.offlineSvc.internetStatus;
                        this.offlineSvc.connectionChanged.subscribe(function (conn) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this._app.manual_sync_processing = conn.status ? true : false;
                                this._app.connected = conn.status;
                                this._app.network = conn;
                                return [2 /*return*/];
                            });
                        }); });
                        this.http
                            .get(environment.api + '/settings')
                            .toPromise()
                            .then(function (res) {
                            _this._app.configs = res;
                            resolve(res);
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return AppSettingsService;
}());
export { AppSettingsService };
