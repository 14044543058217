import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Unsubsribe } from '../../pipe/unsubscribe.class';
import { ReferenceService } from '../../services/reference.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent extends Unsubsribe implements OnInit {
  constructor(private readonly refSvc: ReferenceService) {
    super();
  }

  @Input('default') default: any | undefined;
  @Output('closed') closed = new EventEmitter<void>();

  host = environment.host;
  customers = [];
  filtered_customers = [];
  custom_customer = false;

  ngOnInit() {
    this.init_references();
  }

  private init_references(): void {
    this.refSvc
      .idb_find_v2('business_partners')
      .pipe(takeUntil(this.unsub$))
      .subscribe(docs => {
        this.customers = docs.filter(({ category, is_active }) => is_active && category === 'CUSTOMER');
        this.filtered_customers = this.customers;
      });
  }

  onSearchCustomer(evt: KeyboardEvent | any) {
    const { keyCode } = evt;
    if ([13, 27, 46].includes(keyCode)) evt.target.value = '';
    const search_key = evt.target.value;
    if (search_key.length === 0) return (this.filtered_customers = this.customers);
    this.filtered_customers = this.customers.filter(({ company }) => company.toLowerCase().indexOf(search_key.toLowerCase()) > -1);
  }

  onCustomerInput() {
    this.default.walked_in = true;
    const display_name = this.default.display_name;
    const last = display_name.substring(display_name.indexOf(' ') + 1);
    const first = display_name.substring(0, display_name.length - last.length - 1);
    this.default.fullname = { first, last };
    this.closed.emit();
  }

  onCustomerChanged(customer: any) {
    const { code, company, bill_to, balance, email, contact_no } = customer;
    this.default.display_name = company;
    this.default.walked_in = false;
    this.default.fullname = {
      first: code,
      last: company,
    };
    this.default.email = email;
    this.default.address = bill_to;
    this.default.contact_no = contact_no;
    this.closed.emit();
  }

  onWalkIn() {
    const birthdate = '2014/02/14';
    Object.assign(this.default, {
      avatar: 'uploads/avatar/default.png',
      walked_in: true,
      display_name: 'WALK-IN CUSTOMER',
      members_card: new Date().getTime() + '' + Math.floor(Math.random() * 99999),
      fullname: {
        first: 'WALK-IN',
        last: 'CUSTOMER',
      },
      email: 'email@customer.ph',
      date_of_birth: new DatePipe(birthdate).transform(birthdate, 'yyyy-MM-dd', '+800', 'en-PH'),
      contact_no: '+639176367777',
      address: 'NPA',
    });
    this.closed.emit();
  }
}
