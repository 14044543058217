/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-sidebar-form.component";
var styles_AppSidebarFormComponent = [];
var RenderType_AppSidebarFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarFormComponent, data: {} });
export { RenderType_AppSidebarFormComponent as RenderType_AppSidebarFormComponent };
export function View_AppSidebarFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_AppSidebarFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-form", [], null, null, null, View_AppSidebarFormComponent_0, RenderType_AppSidebarFormComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppSidebarFormComponent, [], null, null)], null, null); }
var AppSidebarFormComponentNgFactory = i0.ɵccf("app-sidebar-form", i1.AppSidebarFormComponent, View_AppSidebarFormComponent_Host_0, {}, {}, []);
export { AppSidebarFormComponentNgFactory as AppSidebarFormComponentNgFactory };
