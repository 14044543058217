import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { OfflineService } from './offline.service';

@Injectable({
  providedIn: 'root',
})
export class NavsNotifService implements OnDestroy {
  private subscriptions = new Subscription();
  public notif_count: number = 0;

  public pullout_count: number = 0;
  public pullout_content: any = [];
  public po_count: number = 0;
  public po_content: any = [];
  public transfer_count: number = 0;
  public transfer_content: any = [];

  public user: any;
  public userauth: any;

  private connected: boolean = false;

  constructor(private api: ApiService, private _auth: AuthService, private offlineSvc: OfflineService, private _toast: ToastrService) {
    this.connected = this.offlineSvc.internetStatus;
    this.subscriptions.add(this.offlineSvc.connectionChanged.subscribe(conn => (this.connected = conn.status)));
    this.subscriptions.add(
      this._auth.loadUser.subscribe(user => {
        this.user = user;
        this.userauth = user.authorization;
      }),
    );
    // this.loadNotif();
    setInterval(() => {
      if (this._auth.loggedIn()) {
        this.loadNotif(this.user);
      }
    }, 1000 * 60 * 10);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadNotif(user: any) {
    if (this.connected) {
      this.notif_count = 0;
      this.pullout_content = [];
      this.po_content = [];
      this.transfer_content = [];
      this.getGI(user);
      this.getPO(user);
      this.getTransfer(user);
    } else {
      this._toast.warning('You are currently offline', 'Unable to load notification');
    }
  }

  getTransfer(user: any) {
    try {
      const filter = user.authorization.purchasing.po_delivery ? '' : '&location=' + user['assign_branch']['_id'];
      this.api._get('/transfer?view=all&statuses=["pending","approved"]' + filter).subscribe(doc => {
        if (doc.success) {
          const pending = doc.data.sts.filter(f => f.status === 'pending');
          const approved = doc.data.sts.filter(f => f.status === 'approved');
          this.transfer_count = doc.data.sts.length;
          this.notif_count += doc.data.sts.length;

          if (pending.length > 0) {
            this.transfer_content.push({
              icon: 'icon-basket-loaded',
              url: '/inventory/transfer/transactions',
              params: { status: 'pending' },
              visible: true,
              count: pending.length,
              text: 'pending for approval.',
              textclass: 'text-warning',
              badge: 'badge-warning',
            });
          }

          if (approved.length > 0) {
            this.transfer_content.push({
              icon: 'icon-pin',
              url: '/inventory/transfer/transactions',
              params: { status: 'approved' },
              visible: true,
              count: approved.length,
              text: 'awaiting to receive.',
              textclass: 'text-info',
              badge: 'badge-info',
            });
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  getPO(user: any) {
    try {
      const filter = user.authorization.purchasing.po_delivery ? '' : '&location=' + user['assign_branch']['_id'];
      this.api._get('/purchase?view=all&statuses=["open","partial"]' + filter).subscribe(doc => {
        if (doc.success) {
          const open = doc.data.pos.filter(f => f.status == 'open');
          const partial = doc.data.pos.filter(f => f.status == 'partial');
          this.po_count = doc.data.pos.length;
          this.notif_count += doc.data.pos.length;

          if (open.length > 0)
            this.po_content.push({
              icon: 'icon-basket-loaded',
              url: '/purchasing/transactions',
              params: { status: 'open' },
              visible: true,
              count: open.length,
              text: 'open purchase/s.',
              textclass: 'text-warning',
              badge: 'badge-warning',
            });

          if (partial.length > 0)
            this.po_content.push({
              icon: 'icon-pin',
              url: '/purchasing/transactions',
              params: { status: 'partial' },
              visible: true,
              count: partial.length,
              text: 'partially received.',
              textclass: 'text-info',
              badge: 'badge-info',
            });
        }
      });
    } catch (err) {
      console.error(err);
    }
  }

  getGI(user: any) {
    try {
      const filter = user.authorization.inventory.show_all ? '' : '&location=' + user['assign_branch']['_id'];
      this.api._get('/pullout?view=all&statuses=["pending","approved"]' + filter).subscribe(doc => {
        const pending = doc.data.gis.filter(f => f.status == 'pending');
        const approved = doc.data.gis.filter(f => f.status == 'approved');

        this.pullout_count = pending.length + approved.length;
        this.notif_count += this.pullout_count;
        if (pending.length > 0)
          this.pullout_content.push({
            icon: 'icon-logout',
            url: '/inventory/pullout/transactions',
            params: { status: 'pending' },
            visible: true,
            count: pending.length,
            text: 'pending for approval.',
            textclass: 'text-warning',
            badge: 'badge-warning',
          });

        if (approved.length > 0)
          this.pullout_content.push({
            icon: 'icon-login',
            url: '/inventory/pullout/transactions',
            params: { status: 'approved' },
            visible: true,
            count: approved.length,
            text: 'pending for release.',
            textclass: 'text-success',
            badge: 'badge-success',
          });
      });
    } catch (err) {
      console.error(err);
    }
  }
}
