import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { CustomerComponent } from './core/components/customer/customer.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ModulesGuard } from './core/guard/modules.guard';
import { OpeningGuard } from './core/guard/opening.guard';

// Import Containers
import { FullLayoutComponent, SimpleLayoutComponent } from './core/layouts/containers';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'dashboard',
        data: { title: 'Dashboard', guard: 'dashboard' },
        loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard, ModulesGuard],
      },
      {
        path: 'store',
        data: { title: 'Store', guard: 'store' },
        loadChildren: './modules/store/store.module#StoreModule',
        canActivate: [AuthGuard, ModulesGuard],
      },
      {
        path: 'products',
        data: { title: 'Items', guard: 'product' },
        loadChildren: './modules/product/product.module#ProductModule',
        canActivate: [AuthGuard, ModulesGuard],
      },
      {
        path: 'products/voucher',
        data: { title: 'Voucher', guard: 'voucher' },
        loadChildren: './modules/voucher/voucher.module#VoucherModule',
        canActivate: [AuthGuard, ModulesGuard],
      },
      {
        path: 'customers',
        data: { title: 'Customers', guard: 'customer' },
        loadChildren: './modules/customer/customer.module#CustomerModule',
        canActivate: [AuthGuard, ModulesGuard],
      },
      {
        path: 'suppliers',
        data: { title: 'Suppliers', guard: 'supplier' },
        loadChildren: './modules/supplier/supplier.module#SupplierModule',
        canActivate: [AuthGuard, ModulesGuard],
      },
      {
        path: 'user',
        loadChildren: './modules/user/user.module#UserModule',
        canActivateChild: [AuthGuard, ModulesGuard],
      },
      {
        path: 'settings',
        data: { title: 'Settings', guard: 'settings' },
        loadChildren: './modules/settings/settings.module#SettingsModule',
        canActivate: [AuthGuard, ModulesGuard],
      },
      {
        path: 'purchasing',
        data: { title: 'Purchasing', guard: 'purchasing' },
        loadChildren: './modules/purchasing/purchasing.module#PurchasingModule',
        canActivateChild: [AuthGuard, ModulesGuard],
      },
      {
        path: 'sales',
        data: { title: 'Sales', guard: 'sales' },
        loadChildren: './modules/sales/sales.module#SalesModule',
        canActivateChild: [AuthGuard, ModulesGuard],
      },
      {
        path: 'return',
        data: { title: 'Return', guard: 'return' },
        loadChildren: './modules/return/return.module#ReturnModule',
        canActivateChild: [AuthGuard, ModulesGuard],
      },
      {
        path: 'inventory',
        data: { title: 'Inventory' },
        children: [
          {
            path: '',
            redirectTo: 'reports',
            pathMatch: 'full',
          },
          {
            path: 'transfer',
            data: { title: 'Transfer', guard: 'transfer' },
            loadChildren: './modules/transfer/transfer.module#TransferModule',
            canActivateChild: [AuthGuard, ModulesGuard],
          },
          {
            path: 'receiving',
            data: { title: 'Receiving', guard: 'receiving' },
            loadChildren: './modules/receiving/receiving.module#ReceivingModule',
            canActivateChild: [AuthGuard, ModulesGuard],
          },
          {
            path: 'pullout',
            data: { title: 'Pullout', guard: 'pullout' },
            loadChildren: './modules/pullout/pullout.module#PulloutModule',
            canActivateChild: [AuthGuard, ModulesGuard],
          },
          {
            path: 'reports',
            data: { title: 'Inventory', guard: 'inventory' },
            loadChildren: './modules/inventory/inventory.module#InventoryModule',
            canActivate: [ModulesGuard],
            canActivateChild: [AuthGuard, ModulesGuard],
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    children: [
      {
        path: 'opening',
        data: { title: 'Opening' },
        loadChildren: './modules/cash-register/cash-register.module#CashRegisterModule',
        canActivateChild: [OpeningGuard],
      },
      {
        path: 'authenticate',
        data: { title: 'Login' },
        loadChildren: './modules/login/login.module#LoginModule',
      },
      {
        path: 'pos',
        data: { title: 'POS' },
        loadChildren: './modules/pos/pos.module#PosModule',
      },
      {
        path: 'sample',
        data: { title: 'sample' },
        component: CustomerComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const RoutesModule = [];
