import { fromEvent, merge, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var OfflineService = /** @class */ (function () {
    function OfflineService() {
        this.internetStatus = false;
        this.internetConnectionChanged = new Subject();
    }
    Object.defineProperty(OfflineService.prototype, "connectionChanged", {
        get: function () {
            return this.internetConnectionChanged.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    OfflineService.prototype.initNetwork = function () {
        var _this = this;
        this.internetConnectionChanged.next(navigator.onLine);
        merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
            .pipe(map(function () { return navigator.onLine; }))
            .subscribe(function (status) {
            var message = status ? 'Connected' : 'Disconnected';
            var toastMessage = status ? "You're internet connection was restored" : 'You are currently offline';
            var toastType = status ? 'success' : 'error';
            _this.internetStatus = status;
            _this.internetConnectionChanged.next({ status: status, message: message, toastMessage: toastMessage, toastType: toastType });
        });
    };
    OfflineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OfflineService_Factory() { return new OfflineService(); }, token: OfflineService, providedIn: "root" });
    return OfflineService;
}());
export { OfflineService };
