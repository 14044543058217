import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AuthService } from './services/auth.service';
import { AuthGuard } from './guard/auth.guard';
import { ModulesGuard } from './guard/modules.guard';
import { OpeningGuard } from './guard/opening.guard';
import { AuthInterceptor } from './services/auth.interceptor';
import { ApiService } from './services/api.service';
import { ErrorsHandler } from './services/error.handler.service';
import { AppSettingsService } from './services/app-settings.service';
import { ReferenceService } from './services/reference.service';
import { AppVariables } from './interfaces/app-variables';
import { SharedService } from './services/shared.service';
import { ComponentsModule } from './components/components.module';

export function init_app(appSettings: AppSettingsService) {
  return () => appSettings.initializeApp();
}

@NgModule({
  declarations: [],
  imports: [CommonModule, ComponentsModule],
  providers: [
    AuthService,
    AuthGuard,
    ModulesGuard,
    OpeningGuard,
    ApiService,
    AppSettingsService,
    ReferenceService,
    AppVariables,
    SharedService,
    { provide: ErrorHandler, useClass: ErrorsHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppSettingsService],
      multi: true,
    },
  ],
})
export class CoreModule {}
