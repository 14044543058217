/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-footer.component";
import * as i2 from "../../../interfaces/app-variables";
var styles_AppFooterComponent = [];
var RenderType_AppFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppFooterComponent, data: {} });
export { RenderType_AppFooterComponent as RenderType_AppFooterComponent };
export function View_AppFooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "footer", [["class", "app-footer d-print-none"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["href", "https://appworx.ph"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Appworx"])), (_l()(), i0.ɵted(5, null, [" \u00A9 2018. ver. ", ""])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "span", [["class", "ml-auto"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    Powered by "])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appVersion; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co._env.company.website; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co._env.company.name; _ck(_v, 10, 0, currVal_2); }); }
export function View_AppFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_AppFooterComponent_0, RenderType_AppFooterComponent)), i0.ɵdid(1, 49152, null, 0, i1.AppFooterComponent, [i2.AppVariables], null, null)], null, null); }
var AppFooterComponentNgFactory = i0.ɵccf("app-footer", i1.AppFooterComponent, View_AppFooterComponent_Host_0, {}, {}, []);
export { AppFooterComponentNgFactory as AppFooterComponentNgFactory };
