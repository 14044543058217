<div class="customer--wrapper" *ngIf="!custom_customer">
  <div class="modal-header d-block pb-1">
    <div class="d-flex justify-content-center align-items-center">
      <strong class="h4 mr-2">SELECT</strong>
      <h4 class="text-muted">CUSTOMER</h4>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex left">
        <button [disabled]="default.walked_in" class="btn btn-outline-primary btn-sm" (click)="onWalkIn()">
          <i class="fa fa-user" aria-hidden="true"></i> Walk-in
        </button>
        <button class="btn btn-outline-warning btn-sm ml-1" (click)="custom_customer = !custom_customer">
          <i class="fa fa-address-card-o" aria-hidden="true"></i> Custom
        </button>
      </div>
      <button class="btn btn-outline-primary btn-sm" (click)="closed.emit()"><i class="fa fa-close"></i></button>
    </div>
  </div>
  <div class="modal-body">
    <div class="search--box">
      <input type="text" (keyup)="onSearchCustomer($event)" #SearchKey class="input--text" placeholder="search customer..." />
      <button class="btn btn-outline-warning btn-sm" (click)="SearchKey.value = ''; filtered_customers = customers">clear</button>
    </div>
    <div class="customer--box">
      <div class="customer--info" *ngFor="let customer of filtered_customers" (click)="onCustomerChanged(customer)">
        <img class="customer--avatar" [src]="host + 'uploads/avatar/default.png'" alt="customer" width="50" height="50" />
        <div class="details">
          <div class="cust--name">{{ customer.company }}</div>
          <div class="cust--address small">
            {{ customer.bill_to }}
          </div>
          <small class="balance">Balance: {{ customer.balance | currency: 'PHP':'symbol-narrow' }}</small>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template [ngIf]="custom_customer">
  <form #frmCustomer="ngForm" (ngSubmit)="onCustomerInput()">
    <div class="modal-header d-block pb-0">
      <div class="d-flex justify-content-center align-items-center">
        <strong class="h4 mr-2">CUSTOMER</strong>
        <h4 class="text-muted">INFORMATION</h4>
      </div>
    </div>
    <div class="modal-body">
      <div class="form-group w-100">
        <label for="display_name">
          <small>Fullname <span class="text-muted">(required)</span></small>
        </label>
        <input type="text" class="form-control text-uppercase" required placeholder="Juan" name="display_name" [(ngModel)]="default.display_name" />
      </div>
      <div class="form-group">
        <label for="email">
          <small>Email <span class="text-muted">(required)</span></small>
        </label>
        <input
          type="text"
          class="form-control"
          required
          placeholder="email@company.domain"
          pattern="[A-Za-z0-9._%+-]+@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]"
          name="email"
          [(ngModel)]="default.email" />
      </div>
      <div class="d-flex">
        <div class="form-group">
          <label for="">
            <small>Birth Date <span class="text-muted">(required)</span></small>
          </label>
          <input type="date" class="form-control" required placeholder="Date of birth" name="date_of_birth" [(ngModel)]="default.date_of_birth" />
        </div>
        <div class="form-group">
          <label for="">
            <small>Contact No. <span class="text-muted">(required)</span></small>
          </label>
          <input
            type="text"
            class="form-control text-uppercase"
            required
            placeholder="+639172565529"
            pattern="^(?:\+639)\d{9}?$"
            name="contact"
            [(ngModel)]="default.contact_no" />
        </div>
      </div>
      <div class="form-group">
        <label for="address">
          <small>Address <span class="text-muted">(required)</span></small>
        </label>
        <textarea
          name="address"
          rows="2"
          class="form-control"
          required
          placeholder="Customer Completed Address"
          [(ngModel)]="default.address"></textarea>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <button class="btn btn-outline-primary btn-sm" (click)="onWalkIn()"><i class="fa fa-user" aria-hidden="true"></i> Default</button>
      <div class="d-flex right">
        <button class="btn btn-outline-secondary btn-sm mr-1" (click)="custom_customer = !custom_customer"><i class="fa fa-close"></i> Cancel</button>
        <button type="submit" class="btn btn-outline-success btn-sm" [disabled]="!frmCustomer.valid"><i class="fa fa-check"></i> Ok</button>
      </div>
    </div>
  </form>
</ng-template>
