import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent {

  constructor(
    private _location: Location
  ) {}

  onRefresh() {
    window.location.reload();
    return false;
  }

  onBack() {
    this._location.back();
    return false;
  }

  onForward() {
    this._location.forward();
    return false;
  }
 }
