<ngx-spinner bdColor="rgba(186,177,177,0.19)" size="default" color="#097a3b" type="ball-pulse"></ngx-spinner>
<ng-progress></ng-progress>
<router-outlet></router-outlet>

<!-- styles -->
<!--
    square-jelly-box
    timer
    ball-scale-pulse
    ball-pulse
-->

<ng-template #sessionTimeOut>
  <div class="modal-header">
    <h4 class="modal-title pull-left"><small>Session Timeout Warning</small></h4>
  </div>
  <div class="modal-body text-center">
    <p>
      <strong>{{ idleState }}</strong>
    </p>
    <div class="mb-2">
      <progressbar class="progress-striped active" [animate]="true" [striped]="true" [value]="progressCount" [max]="max" type="warning"></progressbar>
    </div>
  </div>
  <div class="modal-footer" *ngIf="timeout">
    <button type="button" class="btn btn-outline-success" (click)="onLoginContinue()">
      <small class="badge badge-pill badge-primary mr-1">{{ idleCountdown }}</small> Continue Login?
    </button>
  </div>
</ng-template>
