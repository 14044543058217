<header class="app-header navbar" *ngIf="user">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
  <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3" *ngIf="modules.dashboard">
      <a class="nav-link" routerLink="/dashboard">Dashboard</a>
    </li>
    <li class="nav-item px-3" *ngIf="modules.store">
      <a class="nav-link" routerLink="/store">Stores</a>
    </li>
    <li class="nav-item px-3" *ngIf="modules.user">
      <a class="nav-link" routerLink="/user">Users</a>
    </li>
    <li class="nav-item px-3" *ngIf="modules.settings">
      <a class="nav-link" routerLink="/settings">Settings</a>
    </li>
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item">
      <a class="nav-link" aria-haspopup="true" aria-expanded="false" routerLink="/pos">
        <i class="fa fa-pie-chart" aria-hidden="true"></i>
        <span class="badge badge-pill badge-danger">POS</span>
      </a>
    </li>
    <li class="nav-item d-md-down-none dropdown" dropdown placement="bottom right">
      <a class="nav-link" aria-haspopup="true" aria-expanded="false" dropdownToggle
        ><i class="icon-bell"></i
        ><span class="badge badge-pill badge-danger" *ngIf="_pushNotif.notif_count > 0">{{ _pushNotif.notif_count }}</span></a
      >
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg" *dropdownMenu role="menu">
        <div class="dropdown-header text-center" *ngIf="_pushNotif.po_count > 0">
          <strong>You have {{ _pushNotif.po_count }} purchase/s</strong>
        </div>
        <a [routerLink]="[c.url]" [queryParams]="c.params" *ngFor="let c of _pushNotif.po_content" class="dropdown-item" [class.d-none]="!c.visible">
          <i [ngClass]="[c.icon, c.textclass]"></i><span class="badge-pill badge-secondary" [ngClass]="c.badge">{{ c.count }}</span
          >&nbsp; {{ c.text }}
        </a>
        <div class="dropdown-header text-center" *ngIf="_pushNotif.pullout_count > 0">
          <strong>You have {{ _pushNotif.pullout_count }} pullout/s</strong>
        </div>
        <a
          class="dropdown-item"
          *ngFor="let c of _pushNotif.pullout_content"
          [routerLink]="[c.url]"
          [queryParams]="c.params"
          [class.d-none]="!c.visible">
          <i [ngClass]="[c.icon, c.textclass]"></i><span class="badge-pill badge-secondary" [ngClass]="c.badge">{{ c.count }}</span
          >&nbsp; {{ c.text }}
        </a>
        <div class="dropdown-header text-center" *ngIf="_pushNotif.transfer_count > 0">
          <strong>You have {{ _pushNotif.transfer_count }} transfer/s</strong>
        </div>
        <a
          class="dropdown-item"
          *ngFor="let c of _pushNotif.transfer_content"
          [routerLink]="[c.url]"
          [queryParams]="c.params"
          [class.d-none]="!c.visible">
          <i [ngClass]="[c.icon, c.textclass]"></i><span class="badge-pill badge-secondary" [ngClass]="c.badge">{{ c.count }}</span
          >&nbsp; {{ c.text }}
        </a>
      </div>
    </li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" aria-haspopup="true" aria-expanded="false" dropdownToggle><i class="fa fa-area-chart" aria-hidden="true"></i></a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg" *dropdownMenu role="menu">
        <div class="dropdown-header text-center" *ngIf="modules.inventory">
          <strong>Inventory Reports</strong>
        </div>
        <a routerLink="/inventory/reports/current" class="dropdown-item" *ngIf="modules.inventory && userauth.inventory.current">
          <h6><i class="icon-notebook"></i> Current Inventory</h6>
        </a>
        <a routerLink="/inventory/reports/serials" class="dropdown-item" *ngIf="modules.inventory && userauth.inventory.serial">
          <h6><i class="icon-list"></i> Serial Management</h6>
        </a>
        <a routerLink="/inventory/reports/audit" class="dropdown-item" *ngIf="modules.inventory">
          <h6><i class="icon-book-open"></i> Inventory Audit/Tracking</h6>
        </a>
        <div class="dropdown-header text-center">
          <strong>Sales Reports</strong>
        </div>
        <!-- <a routerLink="/inventory/reports/audit" class="dropdown-item">
          <div class="small mb-1">
            Sales Summary Report
            <span class="float-right">
              <strong>75%</strong>
            </span>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="small mb-1">
            Sales Detailed Report
            <span class="float-right">
              <strong>100%</strong>
            </span>
          </div>
          <span class="progress progress-xs">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"></div>
          </span>
        </a> -->
      </div>
    </li>
    <li class="nav-item d-md-down-none dropdown" dropdown placement="bottom right" *ngIf="false">
      <a class="nav-link" aria-haspopup="true" aria-expanded="false" dropdownToggle>
        <i class="icon-envelope-letter"></i>
        <!-- <span class="badge badge-pill badge-info">7</span> -->
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg" *dropdownMenu role="menu">
        <div class="dropdown-header text-center">
          <strong>You have 4 messages</strong>
        </div>
        <a href="#" class="dropdown-item">
          <div class="message">
            <div class="py-3 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
                <span class="avatar-status badge-success"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">Just now</small>
            </div>
            <div class="text-truncate font-weight-bold"><span class="fa fa-exclamation text-danger"></span> Important message</div>
            <div class="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
            </div>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="message">
            <div class="py-3 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
                <span class="avatar-status badge-warning"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">5 minutes ago</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div class="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
            </div>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="message">
            <div class="py-3 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/5.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
                <span class="avatar-status badge-danger"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">1:52 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div class="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
            </div>
          </div>
        </a>
        <a href="#" class="dropdown-item">
          <div class="message">
            <div class="py-3 mr-3 float-left">
              <div class="avatar">
                <img src="assets/img/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com" />
                <span class="avatar-status badge-info"></span>
              </div>
            </div>
            <div>
              <small class="text-muted">John Doe</small>
              <small class="text-muted float-right mt-1">4:03 PM</small>
            </div>
            <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
            <div class="small text-muted text-truncate">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...
            </div>
          </div>
        </a>
        <a href="#" class="dropdown-item text-center">
          <strong>View all messages</strong>
        </a>
      </div>
    </li>

    <!-- <li class="nav-item">
      <a
        class="nav-link"
        aria-haspopup="true"
        aria-expanded="false"
        (click)="onConnect()"
        [ngClass]="printSvc.printer_connected ? 'text-success' : 'text-danger'">
        <i class="fa fa-print" aria-hidden="true"></i>
      </a>
    </li> -->
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" aria-haspopup="true" aria-expanded="false" dropdownToggle>
        <img src="{{ avatar }}" class="img-avatar" alt="{{ user.email }}" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu role="menu">
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        <a class="dropdown-item">
          <i class="fa fa-bell-o"></i> Notification<span class="badge badge-info">{{ _pushNotif.notif_count }}</span>
        </a>
        <a class="dropdown-item" (click)="onSyncInventory()"> <i class="fa fa-refresh"></i> Sync Inventory </a>
        <a class="dropdown-item" (click)="onSyncReferences()"> <i class="fa fa-file"></i> Sync Server </a>
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Issue Logs<span class="badge badge-success">42</span></a> -->
        <div class="dropdown-header text-center"><strong>Settings</strong></div>
        <a class="dropdown-item" [routerLink]="['/user', user_id]"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" routerLink="/settings"><i class="fa fa-wrench"></i> Setting</a>
        <!-- <a class="dropdown-item" routerLink="/pos"><i class="fa fa-wrench"></i> POS<span class="badge badge-danger">New</span></a> -->
        <div class="divider"></div>
        <!-- <a class="dropdown-item" (click)="onLockClick()"><i class="fa fa-shield"></i> Lock account</a> -->
        <a class="dropdown-item" (click)="onLogoutClick()"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>

    <li class="nav-item" tooltip="{{ internetTooltip }}" placement="left">
      <a
        class="nav-link"
        aria-haspopup="true"
        aria-expanded="false"
        (click)="onSync()"
        [ngClass]="{ 'text-success': internet, 'text-danger': !internet }">
        <i class="fa fa-wifi" aria-hidden="true"></i>
        <span class="badge badge-pill badge-danger" *ngIf="_app.sync > 0">{{ _app.sync }}</span>
      </a>
    </li>
    <!-- <button class="navbar-toggler d-md-down-none" type="button" appAsideMenuToggler>
      <span class="navbar-toggler-icon"></span>
    </button> -->
  </ul>
</header>
