import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {
  IPayment,
  ICategory,
  IBank,
  IChannel,
  IGroup,
  IInstallment,
  IBrand,
  ILifecycle,
  IFamily,
  IType,
  IWarehouse,
  IReward,
} from '../interfaces/references';
import { ToastrService } from 'ngx-toastr';
import { AppVariables } from '../interfaces/app-variables';
import { idb } from '../storage/indexedDB';
import { liveQuery } from 'dexie';
import { Observable } from 'rxjs';

@Injectable()
export class ReferenceService {
  private connected: boolean = false;
  private db: any = null;

  constructor(private api: ApiService, private _app: AppVariables, private _toast: ToastrService) {
    this.db = idb;
  }

  // async idb_find(table: string, params?: object): Promise<any[]> {
  //   const IndexedDB: Array<any> = await idb[table].toArray();
  //   return IndexedDB;
  // }

  idb_find(table: string) {
    return liveQuery(() => idb[table].toArray());
  }

  idb_find_v2(table: string): Observable<any> {
    return new Observable(obs => liveQuery(() => idb[table].toArray()).subscribe(d => obs.next(d)));
  }

  // init_payments(endpoint: string): Promise<IPayment[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load payment type');

  //       this.db.ref_payments.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_payments.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Payment Types Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_categories(endpoint: string): Promise<ICategory[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load categories');

  //       this.db.ref_categories.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_categories.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Category Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_banks(endpoint: string): Promise<IBank[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load banks');

  //       this.db.ref_banks.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_banks.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Bank Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_channels(endpoint: string): Promise<IChannel[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load channels');

  //       this.db.ref_channels.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_channels.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Channel Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_groups(endpoint: string): Promise<IGroup[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load groups');

  //       this.db.ref_groups.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_groups.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Group Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_installments(endpoint: string): Promise<IInstallment[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load installments');

  //       this.db.ref_installments.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_installments.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Installment Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_brands(endpoint: string): Promise<IBrand[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load brands');

  //       this.db.ref_brands.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_brands.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Brand Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_lifecycles(endpoint: string): Promise<ILifecycle[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load lifecycle');

  //       this.db.ref_lifecycles.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_lifecycles.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Lifecycle Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_families(endpoint: string): Promise<IFamily[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load other brands');

  //       this.db.ref_families.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_families.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Other Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_types(endpoint: string): Promise<IType[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load types');

  //       this.db.ref_types.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_types.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Type Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_warehouses(endpoint: string): Promise<IWarehouse[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load users');

  //       this.db.warehouses.bulkPut(data);
  //       resolve(data);
  //       // console.log(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.warehouses.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Not Warehouse Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_rewards(endpoint: string): Promise<IReward[]> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load rewards');

  //       this.db.ref_rewards.bulkPut(data);
  //       resolve(data);
  //     } else {
  //       const localDB: Array<any> = await this.db.ref_rewards.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Reward Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // async init_stores(endpoint: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load stores');

  //       this.db.branches.bulkPut(data.branches);
  //       resolve(data.branches);
  //     } else {
  //       const localDB: Array<any> = await this.db.branches.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Branch Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_users(endpoint: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load users');

  //       this.db.users.bulkPut(data.users);
  //       resolve(data.users);
  //     } else {
  //       const localDB: Array<object> = await this.db.users.toArray();
  //       if (localDB.length == 0) this._toast.warning('No users found that can be use offline', 'No Users Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_suppliers(endpoint: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load rewards');

  //       this.db.business_partners.bulkPut(data.documents);
  //       resolve(data.documents);
  //     } else {
  //       const localDB: Array<any> = await this.db.business_partners.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Reward Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_products(endpoint: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load products');

  //       this.db.products.bulkPut(data.products);
  //       resolve(data.products);
  //     } else {
  //       const localDB: Array<any> = await this.db.products.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Products Not Loaded');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_pricelist(endpoint: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load pricelist');

  //       this.db.pricelist.bulkPut(data.campaigns);
  //       resolve(data.campaigns);
  //     } else {
  //       const localDB: Array<any> = await this.db.pricelist.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Pricelist Not Loaded');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_inventories(endpoint: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load inventory');

  //       this.db.inventories.clear();
  //       this.db.inventories.bulkAdd(data.inventories);
  //       resolve(data.inventories);
  //     } else {
  //       const localDB: Array<any> = await this.db.inventories.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Inventories Not Loaded');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_serials(endpoint: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load inventory');

  //       this.db.serials.clear();
  //       this.db.serials.bulkAdd(data.inventories);
  //       resolve(data.inventories);
  //     } else {
  //       const localDB: Array<any> = await this.db.serials.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Inventories Not Loaded');
  //       resolve(localDB);
  //     }
  //   });
  // }

  // init_members(endpoint: string): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     if (this._app.connected) {
  //       const { success, message, data } = await this.api._get(endpoint).toPromise();
  //       if (!success) return this._toast.error(message, 'Unable to load members');

  //       this.db.members.bulkPut(data.documents);
  //       resolve(data.documents);
  //     } else {
  //       const localDB: Array<any> = await this.db.members.toArray();
  //       if (localDB.length == 0) this._toast.warning('No document found that can be use offline', 'Reward Reference Not Found');
  //       resolve(localDB);
  //     }
  //   });
  // }
}
