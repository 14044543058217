import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgIdleModule } from '@ng-idle/core';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';

// State Management
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
// import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared.module';
import { CoreModule } from './core/core.module';

import { AppRoutingModule } from './app-routing';
import { AppComponent } from './app.component';

// Import containers
import { FullLayoutComponent, SimpleLayoutComponent } from './core/layouts/containers';
const APP_CONTAINERS = [FullLayoutComponent, SimpleLayoutComponent];

// Import templates
import {
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
} from './core/layouts/templates';

const APP_TEMPLATES = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,
];

// Import directives
import { AsideToggleDirective, NAV_DROPDOWN_DIRECTIVES, ReplaceDirective, SIDEBAR_TOGGLE_DIRECTIVES } from './core/directives';
const APP_DIRECTIVES = [AsideToggleDirective, NAV_DROPDOWN_DIRECTIVES, ReplaceDirective, SIDEBAR_TOGGLE_DIRECTIVES];

const socketIoConfig: SocketIoConfig = {
  url: environment.host,
  options: { transports: ['websocket'], extraHeaders: { auth: 'AppworxPH' } },
};

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS, ...APP_TEMPLATES, ...APP_DIRECTIVES],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgProgressModule.forRoot(),
    NgProgressRouterModule,
    NgProgressHttpModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('/ngsw-sync.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    ToastrModule.forRoot({
      closeButton: false,
      maxOpened: 5,
      autoDismiss: true,
      preventDuplicates: true,
      progressBar: true,
      timeOut: 1500,
    }),
    NgxsModule.forRoot([]),
    // NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgIdleModule.forRoot(),
    SocketIoModule.forRoot(socketIoConfig),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
