import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { liveQuery } from 'dexie';
import { idb } from '../../../storage/indexedDB';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { NavsNotifService } from '../../../services/navs-notif.service';
import { OfflineService } from '../../../services/offline.service';
import { AppVariables } from '../../../interfaces/app-variables';
import { PrintService } from '../../../services/print.service';
import { SyncService } from '../../../services/sync.service';
import { WebSocketService } from '../../../services/web-socket.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  user: Observable<any>;
  userauth: any;
  modules: any;
  user_id: string;
  avatar: string;
  internet: boolean = false;
  internetTooltip: string = '';

  sync_count = liveQuery(() => idb.sync_task.count());

  constructor(
    public _app: AppVariables,
    private _authService: AuthService,
    private _location: LocationStrategy,
    public _pushNotif: NavsNotifService,
    private offlineSvc: OfflineService,
    private _router: Router,
    public printSvc: PrintService,
    private syncSvc: SyncService,
    private webSocketSvc: WebSocketService,
  ) {
    this.subscriptions.add(
      this._authService.loadUser.subscribe(user => {
        this.user = user;
        this.userauth = user.authorization;
        this.modules = user.modules;
        this.user_id = user._id;
      }),
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    // this.refreshSyncCnt();
    this.subscriptions.add(this.sync_count.subscribe(cnt => (this._app.sync = cnt)));
    this.internet = this.offlineSvc.internetStatus;
    this.internetTooltip = this.offlineSvc.internetStatus ? 'Connected' : 'Disconnected';
    this.subscriptions.add(
      this.offlineSvc.connectionChanged.subscribe(connection => {
        const { status, message } = connection;
        this.internet = status;
        this.internetTooltip = message;
      }),
    );
    this.subscriptions.add(this._authService.loadMyAvatar.subscribe(avatar => (this.avatar = avatar)));
    this._authService.avatarSubscribe.next(environment.host + this.user['avatar']);
  }

  onConnect() {
    this.printSvc.onConnect();
  }

  onSyncInventory() {
    if (this._app.sync !== 0)
      return this.syncSvc.toast.warning('You have pending transaction/s to sync! Check the offline data storage', 'Sync not allowed!');
    if (!this.internet) return this.syncSvc.toast.warning('You must have internet connection for sync to work', 'Sync not allowed!');
    const { assign_branch } = this.user as any;
    this.syncSvc.init_inventories(assign_branch._id);
  }

  onSyncReferences() {
    this.webSocketSvc.syncRef();
  }

  async onSync() {
    // this._app.sync = await idb.sync_task.count();
    this._router.navigate(['/dashboard/sync']);
  }

  onRefresh() {
    window.location.reload();
  }

  onBack() {
    this._location.back();
  }

  onForward() {
    this._location.forward();
  }

  onLogoutClick() {
    this._authService.logout();
    return false;
  }

  onLockClick() {
    this._authService.logout();
    return false;
  }
}
