export const navigation = [
  {
    name: "Dashboard",
    url: "/dashboard",
    protected: "dashboard",
    icon: "icon-speedometer",
    badge: {
      variant: "info",
      text: "NEW",
    },
  },
  {
    name: "Reports",
    url: "/reports",
    icon: "icon-chart",
    children: [
      {
        name: "Inventory Report",
        url: "/inventory_report",
        icon: "icon-layers",
        protected: "inventory",
        children: [
          {
            name: "Current Inventory",
            url: "/inventory/reports/current",
            icon: "icon-notebook",
            protectChild: ["inventory", "current"],
          },
          {
            name: "Serial Management",
            url: "/inventory/reports/serials",
            icon: "icon-list",
            protectChild: ["inventory", "serial"],
          },
          {
            name: "Inventory Audit",
            url: "/inventory/reports/audit",
            icon: "icon-book-open",
          },
        ],
      },
      {
        name: "Transaction Logs",
        url: "/inventory_report",
        icon: "icon-note",
        children: [
          {
            name: "Purchase History",
            url: "/purchasing/transactions",
            protected: "purchasing",
            icon: "icon-docs",
          },
          {
            name: "Sales History",
            url: "/sales/transactions",
            icon: "icon-pie-chart",
            protected: "sales",
          },
          {
            name: "Sales Return History",
            url: "/return/transactions",
            icon: "icon-loop",
            protected: "return",
          },
          {
            name: "Receiving History",
            url: "/inventory/receiving/transactions",
            icon: "icon-login",
            protected: "receiving",
          },
          {
            name: "Transfer History",
            url: "/inventory/transfer/transactions",
            icon: "icon-directions",
            protected: "transfer",
          },
          {
            name: "Pullout History",
            url: "/inventory/pullout/transactions",
            icon: "icon-logout",
            protected: "pullout",
          },
        ],
      },
    ],
  },
  {
    title: true,
    name: "Transaction Module",
  },
  {
    name: "Purchase Order",
    url: "/purchasing/order",
    icon: "icon-basket",
    protectChild: ["purchasing", "order"],
  },
  {
    name: "Sales",
    url: "/sales",
    icon: "icon-pie-chart",
    protected: "sales",
    children: [
      {
        name: "Sales Invoicing",
        url: "/sales/invoice",
        icon: "icon-pie-chart",
        protectChild: ["sales", "add"],
      },
      {
        name: "Postpaid Sales",
        url: "/sales/postpaid",
        icon: "icon-credit-card",
        protectChild: ["sales", "postpaid"],
      },
    ],
  },

  {
    name: "Inventory",
    url: "/inventory",
    icon: "icon-layers",
    protected: "inventory",
    children: [
      {
        name: "Receiving",
        url: "/inventory/receiving/register",
        icon: "icon-login",
        badge: {
          variant: "warning",
          text: "MANUAL",
        },
        protectChild: ["receiving", "add"],
      },
      {
        name: "Request for transfer",
        url: "/inventory/transfer/register",
        icon: "icon-directions",
        protectChild: ["transfer", "add"],
      },
      {
        name: "Request for Pullout",
        url: "/inventory/pullout/register",
        icon: "icon-logout",
        protectChild: ["pullout", "add"],
      },
    ],
  },
  {
    title: true,
    name: "Master Data",
  },
  {
    name: "Item Master Data",
    url: "/products",
    icon: "icon-grid",
    protected: "product",
    children: [
      {
        name: "Item Master Data",
        url: "/products/master",
        icon: "icon-grid",
        protected: "product",
      },
      {
        name: "Price Book",
        url: "/products/campaign",
        icon: "icon-tag",
        protectChild: ["product", "campaign_list"],
      },
      {
        name: "Electronic Voucher",
        url: "/products/voucher",
        icon: "icon-diamond",
        protected: "voucher",
      },
    ],
  },
  {
    name: "Members",
    url: "/customers",
    icon: "icon-user-following",
    protected: "customer",
  },
  {
    name: "Business Partner",
    url: "/suppliers",
    icon: "icon-screen-tablet",
    protected: "supplier",
  },
  {
    title: true,
    name: "Administration",
  },
  {
    name: "General Settings",
    url: "/settings",
    icon: "icon-settings",
    protected: "settings",
  },
  {
    name: "Store Location",
    url: "/store",
    icon: "icon-location-pin",
    protected: "store",
  },
  {
    name: "Application User",
    url: "/user",
    icon: "icon-people",
    protected: "user",
  },
];
