import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettingsService } from './app-settings.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {
  api_endpoints: string;

  constructor(private _http: HttpClient, private _env: AppSettingsService) {
    this.api_endpoints = environment.api;
  }

  _get(url: string): any {
    return this._http.get<any>(this.api_endpoints + url);
  }

  _getProgress(url: string): any {
    return this._http.get<any>(this.api_endpoints + url, { reportProgress: true, observe: 'events' });
  }

  _post(url: string, data: any) {
    return this._http.post<any>(this.api_endpoints + url, data);
  }

  _patch(url: string, data: any) {
    return this._http.patch<any>(this.api_endpoints + url, data);
  }

  _delete(url: string) {
    return this._http.delete<any>(this.api_endpoints + url);
  }
}
