import { Routes } from '@angular/router';
import { CustomerComponent } from './core/components/customer/customer.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ModulesGuard } from './core/guard/modules.guard';
import { OpeningGuard } from './core/guard/opening.guard';
// Import Containers
import { FullLayoutComponent, SimpleLayoutComponent } from './core/layouts/containers';
var ɵ0 = {
    title: 'Home',
}, ɵ1 = { title: 'Dashboard', guard: 'dashboard' }, ɵ2 = { title: 'Store', guard: 'store' }, ɵ3 = { title: 'Items', guard: 'product' }, ɵ4 = { title: 'Voucher', guard: 'voucher' }, ɵ5 = { title: 'Customers', guard: 'customer' }, ɵ6 = { title: 'Suppliers', guard: 'supplier' }, ɵ7 = { title: 'Settings', guard: 'settings' }, ɵ8 = { title: 'Purchasing', guard: 'purchasing' }, ɵ9 = { title: 'Sales', guard: 'sales' }, ɵ10 = { title: 'Return', guard: 'return' }, ɵ11 = { title: 'Inventory' }, ɵ12 = { title: 'Transfer', guard: 'transfer' }, ɵ13 = { title: 'Receiving', guard: 'receiving' }, ɵ14 = { title: 'Pullout', guard: 'pullout' }, ɵ15 = { title: 'Inventory', guard: 'inventory' }, ɵ16 = { title: 'Opening' }, ɵ17 = { title: 'Login' }, ɵ18 = { title: 'POS' }, ɵ19 = { title: 'sample' };
export var routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: FullLayoutComponent,
        data: ɵ0,
        children: [
            {
                path: 'dashboard',
                data: ɵ1,
                loadChildren: './modules/dashboard/dashboard.module#DashboardModule',
                canActivate: [AuthGuard, ModulesGuard],
            },
            {
                path: 'store',
                data: ɵ2,
                loadChildren: './modules/store/store.module#StoreModule',
                canActivate: [AuthGuard, ModulesGuard],
            },
            {
                path: 'products',
                data: ɵ3,
                loadChildren: './modules/product/product.module#ProductModule',
                canActivate: [AuthGuard, ModulesGuard],
            },
            {
                path: 'products/voucher',
                data: ɵ4,
                loadChildren: './modules/voucher/voucher.module#VoucherModule',
                canActivate: [AuthGuard, ModulesGuard],
            },
            {
                path: 'customers',
                data: ɵ5,
                loadChildren: './modules/customer/customer.module#CustomerModule',
                canActivate: [AuthGuard, ModulesGuard],
            },
            {
                path: 'suppliers',
                data: ɵ6,
                loadChildren: './modules/supplier/supplier.module#SupplierModule',
                canActivate: [AuthGuard, ModulesGuard],
            },
            {
                path: 'user',
                loadChildren: './modules/user/user.module#UserModule',
                canActivateChild: [AuthGuard, ModulesGuard],
            },
            {
                path: 'settings',
                data: ɵ7,
                loadChildren: './modules/settings/settings.module#SettingsModule',
                canActivate: [AuthGuard, ModulesGuard],
            },
            {
                path: 'purchasing',
                data: ɵ8,
                loadChildren: './modules/purchasing/purchasing.module#PurchasingModule',
                canActivateChild: [AuthGuard, ModulesGuard],
            },
            {
                path: 'sales',
                data: ɵ9,
                loadChildren: './modules/sales/sales.module#SalesModule',
                canActivateChild: [AuthGuard, ModulesGuard],
            },
            {
                path: 'return',
                data: ɵ10,
                loadChildren: './modules/return/return.module#ReturnModule',
                canActivateChild: [AuthGuard, ModulesGuard],
            },
            {
                path: 'inventory',
                data: ɵ11,
                children: [
                    {
                        path: '',
                        redirectTo: 'reports',
                        pathMatch: 'full',
                    },
                    {
                        path: 'transfer',
                        data: ɵ12,
                        loadChildren: './modules/transfer/transfer.module#TransferModule',
                        canActivateChild: [AuthGuard, ModulesGuard],
                    },
                    {
                        path: 'receiving',
                        data: ɵ13,
                        loadChildren: './modules/receiving/receiving.module#ReceivingModule',
                        canActivateChild: [AuthGuard, ModulesGuard],
                    },
                    {
                        path: 'pullout',
                        data: ɵ14,
                        loadChildren: './modules/pullout/pullout.module#PulloutModule',
                        canActivateChild: [AuthGuard, ModulesGuard],
                    },
                    {
                        path: 'reports',
                        data: ɵ15,
                        loadChildren: './modules/inventory/inventory.module#InventoryModule',
                        canActivate: [ModulesGuard],
                        canActivateChild: [AuthGuard, ModulesGuard],
                    },
                ],
            },
        ],
    },
    {
        path: '',
        component: SimpleLayoutComponent,
        children: [
            {
                path: 'opening',
                data: ɵ16,
                loadChildren: './modules/cash-register/cash-register.module#CashRegisterModule',
                canActivateChild: [OpeningGuard],
            },
            {
                path: 'authenticate',
                data: ɵ17,
                loadChildren: './modules/login/login.module#LoginModule',
            },
            {
                path: 'pos',
                data: ɵ18,
                loadChildren: './modules/pos/pos.module#PosModule',
            },
            {
                path: 'sample',
                data: ɵ19,
                component: CustomerComponent,
            },
        ],
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export var RoutesModule = [];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19 };
