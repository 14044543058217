import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppVariables } from '../interfaces/app-variables';
import { WebSocketService } from './web-socket.service';
import { OfflineService } from './offline.service';
import { idb } from '../storage/indexedDB';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppSettingsService {
  public settings: object;

  constructor(private http: HttpClient, private _app: AppVariables, private offlineSvc: OfflineService, private webSocketSvc: WebSocketService) {
    this.offlineSvc.initNetwork();
  }

  initializeApp(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.webSocketSvc.syncAll(idb);
      this._app.connected = this.offlineSvc.internetStatus;
      this.offlineSvc.connectionChanged.subscribe(async conn => {
        this._app.manual_sync_processing = conn.status ? true : false;
        this._app.connected = conn.status;
        this._app.network = conn;
      });
      this.http
        .get<any>(environment.api + '/settings')
        .toPromise()
        .then(res => {
          this._app.configs = res;
          resolve(res);
        });
    });
  }
}
