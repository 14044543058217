import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user: any = this._authService.getLoggedUser();
    const nDate: string = this._authService.getDateString();
    if (!this._authService.loggedIn() || user.login_date !== nDate) {
      this._router.navigate(['/authenticate']);
      return false;
    }

    const { cash_drawer_open, assign_branch } = user;
    if (assign_branch.enable_registry) {
      if (cash_drawer_open) {
        return true;
      } else {
        this._router.navigate(['/opening']);
        return false;
      }
    }
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user: any = this._authService.getLoggedUser();
    const nDate: string = this._authService.getDateString();
    if (!this._authService.loggedIn() || user.login_date !== nDate) {
      this._router.navigate(['/authenticate']);
      return false;
    }

    const { cash_drawer_open, assign_branch } = user;
    if (assign_branch.enable_registry) {
      if (cash_drawer_open) {
        return true;
      } else {
        this._router.navigate(['/opening']);
        return false;
      }
    }
    return true;
  }
}
