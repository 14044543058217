import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  // toastr: any;

  constructor(private injector: Injector) {}

  async handleError(error: Error | HttpErrorResponse) {
    const toastr = this.injector.get(ToastrService);
    const auth = this.injector.get(AuthService);

    if (error instanceof HttpErrorResponse) {
      auth.spin_hide();
      if (!navigator.onLine) {
        setTimeout(function () {
          toastr.error('There must be something wrong with your internet connection, It might that data will not be saved', 'No Internet Connection');
        }, 300);
      } else {
        // console.error('Server Error!', error);
        if (error.status === 401) {
          setTimeout(function () {
            toastr.warning('Please try to re-login to gain access!', 'ACCESS EXPIRES!');
          }, 100);
        } else if (error.status === 400) {
          setTimeout(function () {
            toastr.warning(error.error.message, 'Bad Request!');
          }, 100);
        } else {
          if (error.error.errors) {
            const message = error.error.errors.error_info.message || 'Not Found';
            setTimeout(function () {
              toastr.error(message, 'Server Response Failed!');
            }, 100);
          } else {
            const message = error.error.message || error.message;
            // const title = error.statusText || 'Server Error Request';
            setTimeout(function () {
              toastr.error(message, 'Server Response Failed!');
            }, 100);
          }
        }
      }
    } else {
      // console.log(error instanceof VersionError);
      toastr.error("There's a Reference Error on this client", 'Client Error');
    }

    console.error('Error Happens All the time... : ', error);
  }
}
