<!-- <div class="sidebar-header"></div> -->
<div class="sidebar-header" *ngIf="user">
  <a [routerLink]="['/user', user._id]" class="d-none d-sm-none d-md-inline">
    <img src="{{ avatar }}" height="80" width="80" class="img-avatar mt-2 mb-1" alt="Avatar" />
  </a>
  <div>
    <strong>{{ user.fullname.first + ' ' + user.fullname.last }}</strong>
  </div>
  <div class="text-muted" *ngIf="!user.superuser">
    <small>{{ user.assign_branch.brn_name }}</small>
  </div>
  <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
    <button type="button" class="btn btn-link" *ngIf="user.modules.settings" routerLink="/settings">
      <i class="icon-settings"></i>
    </button>
    <button type="button" class="btn btn-link" (click)="onLogoutClick()">
      <i class="icon-lock"></i>
    </button>
    <div class="btn-group" role="group" dropdown>
      <button type="button" class="btn btn-link" dropdownToggle aria-haspopup="true" aria-expanded="false">
        <i class="icon-user"></i>
      </button>
      <div *dropdownMenu class="dropdown-menu" role="menu">
        <div class="dropdown-header text-center">
          <strong>Account</strong>
        </div>
        <a class="dropdown-item" href="#"><i class="fa fa-bell-o"></i> Updates<span class="badge badge-info">42</span></a>
        <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span class="badge badge-success">42</span></a>
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-tasks"></i> Tasks<span class="badge badge-danger">42</span></a>
                <a class="dropdown-item" href="#"><i class="fa fa-comments"></i> Comments<span class="badge badge-warning">42</span></a> -->
        <div class="dropdown-header text-center">
          <strong>Settings</strong>
        </div>
        <a class="dropdown-item" [routerLink]="['/user', user._id]"><i class="fa fa-user"></i> Profile</a>
        <a class="dropdown-item" [routerLink]="['/settings']"><i class="fa fa-wrench"></i> Settings</a>
        <!-- <a class="dropdown-item" href="#"><i class="fa fa-usd"></i> Payments<span class="badge badge-secondary">42</span></a>
                <a class="dropdown-item" href="#"><i class="fa fa-file"></i> Projects<span class="badge badge-primary">42</span></a> -->
        <div class="divider"></div>
        <a class="dropdown-item" (click)="onLockClick()"><i class="fa fa-shield"></i> Lock Account</a>
        <a class="dropdown-item" (click)="onLogoutClick()"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </div>
  </div>
</div>
