import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { tokenNotExpired } from 'angular2-jwt';
import { NgxSpinnerService } from 'ngx-spinner';
import { Idle } from '@ng-idle/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  public avatarSubscribe = new BehaviorSubject<any>('');
  public loadMyAvatar = this.avatarSubscribe.asObservable();

  public userSubscribe = new BehaviorSubject<any>(this.getUser());
  public loadUser = this.userSubscribe.asObservable();

  public last_logged_user: any;
  public last_used_password: string;

  constructor(public _idle: Idle, private _spin: NgxSpinnerService, private router: Router) {
    // this.avatarSubscribe.next();
  }

  getDateString(): string {
    const n = new Date();
    return `${n.getFullYear()}-${String(n.getMonth() + 1).padStart(2, '0')}-${n.getDate().toString().padStart(2, '0')}`;
    // return n.toDateString();
  }

  storeUserData(token, user) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.userSubscribe.next(user);
  }

  getLoggedUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getAuth(): void {
    const x = JSON.parse(localStorage.getItem('user'));
    return x.authorization;
  }

  getUser() {
    const x = JSON.parse(localStorage.getItem('user'));
    if (x) {
      return x;
    } else {
      return {};
    }
  }

  setUser(user) {
    localStorage.removeItem('user');
    localStorage.setItem('user', JSON.stringify(user));
    this.userSubscribe.next(user);
  }

  loggedIn() {
    return tokenNotExpired();
  }

  logout() {
    localStorage.clear();
    this._idle.stop();
    this.router.navigate(['/authenticate']);
  }

  spin_show(): void {
    this._spin.show();
  }
  spin_hide(): void {
    this._spin.hide();
  }
}
