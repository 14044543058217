import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { idb } from '../../core/storage/indexedDB';
import bcrypt from 'bcryptjs';
import { timeout, retry, catchError, share } from 'rxjs/operators';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/services/auth.service';
import { NavsNotifService } from '../../core/services/navs-notif.service';
import { SyncService } from '../../core/services/sync.service';
import { AppVariables } from '../../core/interfaces/app-variables';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public fromIdleState: boolean = false;
  public visible: boolean = false;

  constructor(
    public _app: AppVariables,
    private _authService: AuthService,
    private _api: ApiService,
    public _router: Router,
    public _toast: ToastrService,
    private _spin: NgxSpinnerService,
    private _idle: Idle,
    private _pushNofif: NavsNotifService,
    private _location: LocationStrategy,
    private syncSvc: SyncService,
  ) {}

  async authenticate(credential: { username: string; password: string }) {
    const { username, password } = credential;
    let local_user: any = null;
    const login_date = this._authService.getDateString();

    this._spin.show();
    const authenticated = await this._api
      ._post('/users/authenticate', credential)
      .pipe(
        timeout(5000),
        retry(2),
        catchError(async () => {
          local_user = await this.validateOfflineLogin(credential);
          return { offline: true, local_user };
        }),
        share(),
      )
      .toPromise();
    this._spin.hide();

    if (!authenticated.offline) {
      const { success, message, data } = authenticated;
      if (!success) return this._toast.error(message, 'Login Failed');

      const { token, user } = data;
      local_user = { ...user, token, timestamp: Date.now(), message, login_date };
    } else {
      local_user = { ...authenticated.local_user, login_date };
      if (!authenticated.local_user) return;
    }

    const { token, assign_branch, cash_drawer_open, modules, _id, message } = local_user;
    idb.logins.put(local_user);

    // if (!assign_branch.enable_registry) local_user.modules.dashboard = false;

    this._idle.watch();
    this._authService.last_used_password = password;
    this._authService.storeUserData(token, local_user);
    this._pushNofif.loadNotif(local_user);

    if (this.fromIdleState) return this._location.back();
    // if (this._app.sync === 0) this.syncSvc.init_inventories(assign_branch._id, true);

    // if (!this._app.connected) return this._router.navigate(['/sales/invoice']);
    if (!assign_branch.enable_registry) {
      return this._router.navigate(['/user', _id]);
    }

    if (cash_drawer_open) {
      if (modules.dashboard) return this._router.navigate(['/dashboard']);
      this._router.navigate(['/user', _id]);
    } else {
      this._toast.warning(message, null, { timeOut: 5000, progressAnimation: 'increasing' });
      this._router.navigate(['/opening']);
    }
  }

  async validateOfflineLogin(credential: any) {
    const { username, password } = credential;
    const ZDateReading = this.getZReadDate();

    const idb_user = await idb.logins.get({ username });
    if (!idb_user) {
      this._toast.error('User was not found in the local collection! Try establishing connection to the internet', 'Login Failed!', {
        timeOut: 5000,
      });
      return false;
    }

    const isMatch = bcrypt.compareSync(password, idb_user.pincode);
    if (!isMatch) {
      this._toast.error('When offline use pincode instead', 'Pincode does not match!');
      return false;
    }

    const { assign_branch } = idb_user;

    const registry = await idb.dashboard.where(['ref_id+branch._id']).equals([ZDateReading, assign_branch._id]).first();
    const registries = await idb.dashboard
      .orderBy('ref_id', 'desc')
      .filter(reg => {
        return reg.ref_id != ZDateReading && reg.status == 'open' && reg.branch._id == assign_branch._id;
      })
      .toArray();

    let status = registry ? true : false;
    let message = '';

    idb_user.cash_drawer_open = registries.length === 0 && status && registry.status == 'open';
    idb_user.disable_opening = registries.length !== 0 || (status && registry.status == 'closed');

    if (!idb_user.disable_opening && idb_user.cash_drawer_open) {
      idb_user.cash_registry_id = registry._id;
      message = 'Register is already open and successfully authenticated!';
    } else if (!idb_user.disable_opening && !idb_user.cash_drawer_open) {
      idb_user.cash_registry_id = null;
      idb_user.message = 'No open registry yet!';
    } else if (idb_user.disable_opening && !idb_user.cash_drawer_open) {
      idb_user.cash_registry_id = null;
      idb_user.message = "You've already closed the store, contact admin if you wanted to re-open today's registry";
    } else {
      idb_user.cash_registry_id = null;
      idb_user.message = 'You must close all open z-reading before you can start a new';
    }

    idb_user.timestamp = Date.now();
    return idb_user;
  }

  getZReadDate() {
    const ndate = new Date();
    const yyyy = ndate.getFullYear().toString();
    const mm = String(ndate.getMonth() + 1).padStart(2, '0');
    const dd = String(ndate.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  }
}
