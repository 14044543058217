import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class OpeningGuard implements CanActivateChild {
  constructor(private _authService: AuthService, private _router: Router) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { protect } = next.data;
    if (this._authService.loggedIn()) {
      if (protect) {
        const { cash_drawer_open } = this._authService.getLoggedUser();
        if (cash_drawer_open) {
          this._router.navigate(['/dashboard']);
          return false;
        }
        return true;
      } else {
        return true;
      }
    } else {
      this._router.navigate(['/authenticate']);
      return false;
    }
  }
}
