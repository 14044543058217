import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AppVariables } from '../../../interfaces/app-variables';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html'
})
export class AppFooterComponent {
  public _env:any;
  public appVersion: string;

  constructor(
    private _app: AppVariables
  ) {
    this._env = _app.configs;
    this.appVersion = environment.appVersion
  }
}
