import { Injectable } from '@angular/core';
import { fromEvent, merge, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  internetStatus: boolean = false;
  internetConnectionChanged = new Subject<any>();

  get connectionChanged() {
    return this.internetConnectionChanged.asObservable();
  }

  initNetwork() {
    this.internetConnectionChanged.next(navigator.onLine);
    merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        const message = status ? 'Connected' : 'Disconnected';
        const toastMessage = status ? "You're internet connection was restored" : 'You are currently offline';
        const toastType = status ? 'success' : 'error';
        this.internetStatus = status;
        this.internetConnectionChanged.next({ status, message, toastMessage, toastType });
      });
  }
}
