import { Component, ViewChild, TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import { Idle, EventTargetInterruptSource } from '@ng-idle/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './modules/login/login.service';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from './core/services/auth.service';
import { OfflineService } from './core/services/offline.service';
import { SharedService } from './core/services/shared.service';
import { SyncService } from './core/services/sync.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  @ViewChild('sessionTimeOut', { static: false }) sessionTimeOut: TemplateRef<any>;
  private subscriptions = new Subscription();

  timeout: boolean = false;

  idleState = 'Not started.';

  /*** Set in second ****/
  idleSet: number = 420;
  idleContinue: number = 60;
  max: number = 120;
  // idleSet: number = 5;
  // idleContinue: number = 5;
  // max: number = 5;
  idleCountdown: number;
  /******* END **********/

  progressCount: number = 0;

  constructor(
    private idle: Idle,
    private _shared: SharedService,
    private element: ElementRef,
    private _router: Router,
    private _auth: AuthService,
    private offlineSvc: OfflineService,
    private _toast: ToastrService,
    private loginSvc: LoginService,
    private swUpdate: SwUpdate,
    private syncSvc: SyncService,
  ) {
    this.checkForUpdates();
    // Notify for any Network Status change
    this.subscriptions.add(
      this.offlineSvc.internetConnectionChanged.subscribe(connection => {
        const { message, toastMessage, toastType } = connection;
        this.syncSvc.sync();
        this._toast[toastType](toastMessage, message, { progressAnimation: 'increasing', timeOut: 1000 });
      }),
    );

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(this.idleSet);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this.max);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts([
      new EventTargetInterruptSource(this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown mousemove touchstart touchmove scroll'),
    ]);

    this.subscriptions.add(
      idle.onIdleEnd.subscribe(() => {
        this.idleState = 'No longer idle.';
        this._shared.modalRef.hide();
      }),
    );

    this.subscriptions.add(
      idle.onTimeout.subscribe(() => {
        this._auth.last_logged_user = JSON.parse(localStorage.getItem('user'));
        this.loginSvc.fromIdleState = true;

        this.idleState = 'Session Timed out!';
        this._auth.logout();
        this._router.navigate(['/authenticate']);
        this.timeout = true;

        const intId = setInterval(() => {
          this.idleCountdown--;
          if (this.idleCountdown == 0) {
            clearInterval(intId);
            this._shared.modalRef.hide();
          }
        }, 1000);

        setTimeout(() => {
          this._shared.modalRef.hide();
        }, 1000 * this.idleContinue);
      }),
    );

    this.subscriptions.add(
      idle.onIdleStart.subscribe(() => {
        this.loginSvc.fromIdleState = false;
        this.idleCountdown = this.idleContinue;
        this.timeout = false;
        this.idleState = "You've gone idle!";
        this._shared.openModal(this.sessionTimeOut, 'modal-sm');
      }),
    );

    this.subscriptions.add(
      idle.onTimeoutWarning.subscribe(countdown => {
        const countMinutes: number = Math.floor(countdown / 60);
        const countSeconds: number = countdown % 60;

        let count_down: string = countMinutes > 0 ? countMinutes.toString() : '';
        count_down += countMinutes > 1 ? ' minutes ' : countMinutes == 1 ? ' minute ' : '';
        count_down += countSeconds > 0 ? countSeconds.toString() + ' seconds!' : '';

        this.loginSvc.fromIdleState = true;
        this.idleState = 'You will time out in ' + count_down;
        this.progressCount = this.max - (countdown - 1);
      }),
    );

    if (this._auth.loggedIn()) this.reset();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private checkForUpdates() {
    navigator.serviceWorker.ready.then(() => {
      this.subscriptions.add(
        this.swUpdate.available.subscribe(e => {
          console.log(`Current `, e.current, `Available `, e.available);
          const message = `A new version of the App is available`;
          if (confirm(`${message}, Do you want to update?`)) {
            this.swUpdate.activateUpdate().then(() => location.reload());
          }
        }),
      );
    });
  }

  reset() {
    this.idle.watch();
    this.timeout = false;
  }

  async onLoginContinue() {
    this._shared.modalRef.hide();
    if (!this._auth.last_used_password) return false;

    const data = {
      username: this._auth.last_logged_user.username,
      password: this._auth.last_used_password,
    };

    this.loginSvc.authenticate(data);
  }
}
