import { Injectable } from '@angular/core';

@Injectable()
export class AppVariables {
  public configs: any;
  public connected: boolean;
  public network: any;
  public sync: number;
  public manual_sync_processing: boolean = false;
  public printer_connected: boolean = false;
  public printer_characteristic: any;
  public printing: boolean = false;
}
