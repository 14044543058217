import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-sidebar-header',
  templateUrl: './app-sidebar-header.component.html',
})
export class AppSidebarHeaderComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  user: Observable<any>;
  avatar: string;

  constructor(private _authService: AuthService, private _router: Router) {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    // this.user = this._authService.getLoggedUser();
    this.subscriptions.add(this._authService.loadUser.subscribe(user => (this.user = user)));
    this.subscriptions.add(this._authService.loadMyAvatar.subscribe(avatar => (this.avatar = avatar)));
  }

  onLogoutClick() {
    this._authService.logout();
    this._router.navigate(['/authenticate']);
    return false;
  }

  onLockClick() {
    this._authService.last_logged_user = this.user;
    this._authService.logout();
    return false;
  }
}
