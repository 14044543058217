import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import * as es6printJS from 'print-js';
import printJS from 'print-js/dist/print.js';
// import printJS from 'print-js';

@Injectable()
export class SharedService {
  public modalRef: BsModalRef;
  public modalRef2: BsModalRef;
  public modalRef3: BsModalRef;
  public modalRef4: BsModalRef;

  public last_visited_route: string;

  constructor(private _modal: BsModalService) {}

  openModal(template: TemplateRef<any>, clss: string = '') {
    this.modalRef = this._modal.show(template, { backdrop: 'static', class: clss });
  }

  openModal2(template: TemplateRef<any>, clss: string = '') {
    this.modalRef2 = this._modal.show(template, { backdrop: 'static', class: clss });
  }

  openModal3(template: TemplateRef<any>, clss: string = '') {
    this.modalRef3 = this._modal.show(template, { backdrop: 'static', class: clss });
  }

  openModal4(template: TemplateRef<any>, clss: string = '') {
    this.modalRef4 = this._modal.show(template, { backdrop: 'static', class: clss });
  }

  onPrint(element_id: string = '') {
    // printJS({ printable: element_id, type: 'html', maxWidth: 992, targetStyles: ['*']});
    // printJS({ printable: element_id, type: 'html', maxWidth: 1020, targetStyles: ['*']});
    // printJS({ printable: element_id, type: 'html', targetStyles: ['*']});
    window.print();
  }
}
