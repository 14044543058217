import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { liveQuery } from 'dexie';
import { idb } from '../../../storage/indexedDB';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { NavsNotifService } from '../../../services/navs-notif.service';
import { OfflineService } from '../../../services/offline.service';
import { AppVariables } from '../../../interfaces/app-variables';
import { PrintService } from '../../../services/print.service';
import { SyncService } from '../../../services/sync.service';
import { WebSocketService } from '../../../services/web-socket.service';
var AppHeaderComponent = /** @class */ (function () {
    function AppHeaderComponent(_app, _authService, _location, _pushNotif, offlineSvc, _router, printSvc, syncSvc, webSocketSvc) {
        var _this = this;
        this._app = _app;
        this._authService = _authService;
        this._location = _location;
        this._pushNotif = _pushNotif;
        this.offlineSvc = offlineSvc;
        this._router = _router;
        this.printSvc = printSvc;
        this.syncSvc = syncSvc;
        this.webSocketSvc = webSocketSvc;
        this.subscriptions = new Subscription();
        this.internet = false;
        this.internetTooltip = '';
        this.sync_count = liveQuery(function () { return idb.sync_task.count(); });
        this.subscriptions.add(this._authService.loadUser.subscribe(function (user) {
            _this.user = user;
            _this.userauth = user.authorization;
            _this.modules = user.modules;
            _this.user_id = user._id;
        }));
    }
    AppHeaderComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    AppHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.refreshSyncCnt();
        this.subscriptions.add(this.sync_count.subscribe(function (cnt) { return (_this._app.sync = cnt); }));
        this.internet = this.offlineSvc.internetStatus;
        this.internetTooltip = this.offlineSvc.internetStatus ? 'Connected' : 'Disconnected';
        this.subscriptions.add(this.offlineSvc.connectionChanged.subscribe(function (connection) {
            var status = connection.status, message = connection.message;
            _this.internet = status;
            _this.internetTooltip = message;
        }));
        this.subscriptions.add(this._authService.loadMyAvatar.subscribe(function (avatar) { return (_this.avatar = avatar); }));
        this._authService.avatarSubscribe.next(environment.host + this.user['avatar']);
    };
    AppHeaderComponent.prototype.onConnect = function () {
        this.printSvc.onConnect();
    };
    AppHeaderComponent.prototype.onSyncInventory = function () {
        if (this._app.sync !== 0)
            return this.syncSvc.toast.warning('You have pending transaction/s to sync! Check the offline data storage', 'Sync not allowed!');
        if (!this.internet)
            return this.syncSvc.toast.warning('You must have internet connection for sync to work', 'Sync not allowed!');
        var assign_branch = this.user.assign_branch;
        this.syncSvc.init_inventories(assign_branch._id);
    };
    AppHeaderComponent.prototype.onSyncReferences = function () {
        this.webSocketSvc.syncRef();
    };
    AppHeaderComponent.prototype.onSync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // this._app.sync = await idb.sync_task.count();
                this._router.navigate(['/dashboard/sync']);
                return [2 /*return*/];
            });
        });
    };
    AppHeaderComponent.prototype.onRefresh = function () {
        window.location.reload();
    };
    AppHeaderComponent.prototype.onBack = function () {
        this._location.back();
    };
    AppHeaderComponent.prototype.onForward = function () {
        this._location.forward();
    };
    AppHeaderComponent.prototype.onLogoutClick = function () {
        this._authService.logout();
        return false;
    };
    AppHeaderComponent.prototype.onLockClick = function () {
        this._authService.logout();
        return false;
    };
    return AppHeaderComponent;
}());
export { AppHeaderComponent };
