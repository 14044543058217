import * as tslib_1 from "tslib";
import { TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import { Idle, EventTargetInterruptSource } from '@ng-idle/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './modules/login/login.service';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from './core/services/auth.service';
import { OfflineService } from './core/services/offline.service';
import { SharedService } from './core/services/shared.service';
import { SyncService } from './core/services/sync.service';
import { Subscription } from 'rxjs';
var AppComponent = /** @class */ (function () {
    function AppComponent(idle, _shared, element, _router, _auth, offlineSvc, _toast, loginSvc, swUpdate, syncSvc) {
        var _this = this;
        this.idle = idle;
        this._shared = _shared;
        this.element = element;
        this._router = _router;
        this._auth = _auth;
        this.offlineSvc = offlineSvc;
        this._toast = _toast;
        this.loginSvc = loginSvc;
        this.swUpdate = swUpdate;
        this.syncSvc = syncSvc;
        this.subscriptions = new Subscription();
        this.timeout = false;
        this.idleState = 'Not started.';
        /*** Set in second ****/
        this.idleSet = 420;
        this.idleContinue = 60;
        this.max = 120;
        /******* END **********/
        this.progressCount = 0;
        this.checkForUpdates();
        // Notify for any Network Status change
        this.subscriptions.add(this.offlineSvc.internetConnectionChanged.subscribe(function (connection) {
            var message = connection.message, toastMessage = connection.toastMessage, toastType = connection.toastType;
            _this.syncSvc.sync();
            _this._toast[toastType](toastMessage, message, { progressAnimation: 'increasing', timeOut: 1000 });
        }));
        // sets an idle timeout of 5 seconds, for testing purposes.
        idle.setIdle(this.idleSet);
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        idle.setTimeout(this.max);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts([
            new EventTargetInterruptSource(this.element.nativeElement, 'keydown DOMMouseScroll mousewheel mousedown mousemove touchstart touchmove scroll'),
        ]);
        this.subscriptions.add(idle.onIdleEnd.subscribe(function () {
            _this.idleState = 'No longer idle.';
            _this._shared.modalRef.hide();
        }));
        this.subscriptions.add(idle.onTimeout.subscribe(function () {
            _this._auth.last_logged_user = JSON.parse(localStorage.getItem('user'));
            _this.loginSvc.fromIdleState = true;
            _this.idleState = 'Session Timed out!';
            _this._auth.logout();
            _this._router.navigate(['/authenticate']);
            _this.timeout = true;
            var intId = setInterval(function () {
                _this.idleCountdown--;
                if (_this.idleCountdown == 0) {
                    clearInterval(intId);
                    _this._shared.modalRef.hide();
                }
            }, 1000);
            setTimeout(function () {
                _this._shared.modalRef.hide();
            }, 1000 * _this.idleContinue);
        }));
        this.subscriptions.add(idle.onIdleStart.subscribe(function () {
            _this.loginSvc.fromIdleState = false;
            _this.idleCountdown = _this.idleContinue;
            _this.timeout = false;
            _this.idleState = "You've gone idle!";
            _this._shared.openModal(_this.sessionTimeOut, 'modal-sm');
        }));
        this.subscriptions.add(idle.onTimeoutWarning.subscribe(function (countdown) {
            var countMinutes = Math.floor(countdown / 60);
            var countSeconds = countdown % 60;
            var count_down = countMinutes > 0 ? countMinutes.toString() : '';
            count_down += countMinutes > 1 ? ' minutes ' : countMinutes == 1 ? ' minute ' : '';
            count_down += countSeconds > 0 ? countSeconds.toString() + ' seconds!' : '';
            _this.loginSvc.fromIdleState = true;
            _this.idleState = 'You will time out in ' + count_down;
            _this.progressCount = _this.max - (countdown - 1);
        }));
        if (this._auth.loggedIn())
            this.reset();
    }
    AppComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    AppComponent.prototype.checkForUpdates = function () {
        var _this = this;
        navigator.serviceWorker.ready.then(function () {
            _this.subscriptions.add(_this.swUpdate.available.subscribe(function (e) {
                console.log("Current ", e.current, "Available ", e.available);
                var message = "A new version of the App is available";
                if (confirm(message + ", Do you want to update?")) {
                    _this.swUpdate.activateUpdate().then(function () { return location.reload(); });
                }
            }));
        });
    };
    AppComponent.prototype.reset = function () {
        this.idle.watch();
        this.timeout = false;
    };
    AppComponent.prototype.onLoginContinue = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                this._shared.modalRef.hide();
                if (!this._auth.last_used_password)
                    return [2 /*return*/, false];
                data = {
                    username: this._auth.last_logged_user.username,
                    password: this._auth.last_used_password,
                };
                this.loginSvc.authenticate(data);
                return [2 /*return*/];
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
