import { OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { OfflineService } from './offline.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./auth.service";
import * as i3 from "./offline.service";
import * as i4 from "ngx-toastr";
var NavsNotifService = /** @class */ (function () {
    function NavsNotifService(api, _auth, offlineSvc, _toast) {
        var _this = this;
        this.api = api;
        this._auth = _auth;
        this.offlineSvc = offlineSvc;
        this._toast = _toast;
        this.subscriptions = new Subscription();
        this.notif_count = 0;
        this.pullout_count = 0;
        this.pullout_content = [];
        this.po_count = 0;
        this.po_content = [];
        this.transfer_count = 0;
        this.transfer_content = [];
        this.connected = false;
        this.connected = this.offlineSvc.internetStatus;
        this.subscriptions.add(this.offlineSvc.connectionChanged.subscribe(function (conn) { return (_this.connected = conn.status); }));
        this.subscriptions.add(this._auth.loadUser.subscribe(function (user) {
            _this.user = user;
            _this.userauth = user.authorization;
        }));
        // this.loadNotif();
        setInterval(function () {
            if (_this._auth.loggedIn()) {
                _this.loadNotif(_this.user);
            }
        }, 1000 * 60 * 10);
    }
    NavsNotifService.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    NavsNotifService.prototype.loadNotif = function (user) {
        if (this.connected) {
            this.notif_count = 0;
            this.pullout_content = [];
            this.po_content = [];
            this.transfer_content = [];
            this.getGI(user);
            this.getPO(user);
            this.getTransfer(user);
        }
        else {
            this._toast.warning('You are currently offline', 'Unable to load notification');
        }
    };
    NavsNotifService.prototype.getTransfer = function (user) {
        var _this = this;
        try {
            var filter = user.authorization.purchasing.po_delivery ? '' : '&location=' + user['assign_branch']['_id'];
            this.api._get('/transfer?view=all&statuses=["pending","approved"]' + filter).subscribe(function (doc) {
                if (doc.success) {
                    var pending = doc.data.sts.filter(function (f) { return f.status === 'pending'; });
                    var approved = doc.data.sts.filter(function (f) { return f.status === 'approved'; });
                    _this.transfer_count = doc.data.sts.length;
                    _this.notif_count += doc.data.sts.length;
                    if (pending.length > 0) {
                        _this.transfer_content.push({
                            icon: 'icon-basket-loaded',
                            url: '/inventory/transfer/transactions',
                            params: { status: 'pending' },
                            visible: true,
                            count: pending.length,
                            text: 'pending for approval.',
                            textclass: 'text-warning',
                            badge: 'badge-warning',
                        });
                    }
                    if (approved.length > 0) {
                        _this.transfer_content.push({
                            icon: 'icon-pin',
                            url: '/inventory/transfer/transactions',
                            params: { status: 'approved' },
                            visible: true,
                            count: approved.length,
                            text: 'awaiting to receive.',
                            textclass: 'text-info',
                            badge: 'badge-info',
                        });
                    }
                }
            });
        }
        catch (err) {
            console.error(err);
        }
    };
    NavsNotifService.prototype.getPO = function (user) {
        var _this = this;
        try {
            var filter = user.authorization.purchasing.po_delivery ? '' : '&location=' + user['assign_branch']['_id'];
            this.api._get('/purchase?view=all&statuses=["open","partial"]' + filter).subscribe(function (doc) {
                if (doc.success) {
                    var open_1 = doc.data.pos.filter(function (f) { return f.status == 'open'; });
                    var partial = doc.data.pos.filter(function (f) { return f.status == 'partial'; });
                    _this.po_count = doc.data.pos.length;
                    _this.notif_count += doc.data.pos.length;
                    if (open_1.length > 0)
                        _this.po_content.push({
                            icon: 'icon-basket-loaded',
                            url: '/purchasing/transactions',
                            params: { status: 'open' },
                            visible: true,
                            count: open_1.length,
                            text: 'open purchase/s.',
                            textclass: 'text-warning',
                            badge: 'badge-warning',
                        });
                    if (partial.length > 0)
                        _this.po_content.push({
                            icon: 'icon-pin',
                            url: '/purchasing/transactions',
                            params: { status: 'partial' },
                            visible: true,
                            count: partial.length,
                            text: 'partially received.',
                            textclass: 'text-info',
                            badge: 'badge-info',
                        });
                }
            });
        }
        catch (err) {
            console.error(err);
        }
    };
    NavsNotifService.prototype.getGI = function (user) {
        var _this = this;
        try {
            var filter = user.authorization.inventory.show_all ? '' : '&location=' + user['assign_branch']['_id'];
            this.api._get('/pullout?view=all&statuses=["pending","approved"]' + filter).subscribe(function (doc) {
                var pending = doc.data.gis.filter(function (f) { return f.status == 'pending'; });
                var approved = doc.data.gis.filter(function (f) { return f.status == 'approved'; });
                _this.pullout_count = pending.length + approved.length;
                _this.notif_count += _this.pullout_count;
                if (pending.length > 0)
                    _this.pullout_content.push({
                        icon: 'icon-logout',
                        url: '/inventory/pullout/transactions',
                        params: { status: 'pending' },
                        visible: true,
                        count: pending.length,
                        text: 'pending for approval.',
                        textclass: 'text-warning',
                        badge: 'badge-warning',
                    });
                if (approved.length > 0)
                    _this.pullout_content.push({
                        icon: 'icon-login',
                        url: '/inventory/pullout/transactions',
                        params: { status: 'approved' },
                        visible: true,
                        count: approved.length,
                        text: 'pending for release.',
                        textclass: 'text-success',
                        badge: 'badge-success',
                    });
            });
        }
        catch (err) {
            console.error(err);
        }
    };
    NavsNotifService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavsNotifService_Factory() { return new NavsNotifService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.OfflineService), i0.ɵɵinject(i4.ToastrService)); }, token: NavsNotifService, providedIn: "root" });
    return NavsNotifService;
}());
export { NavsNotifService };
