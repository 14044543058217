/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../directives/sidebar/sidebar.directive";
import * as i2 from "./app-sidebar-minimizer.component";
var styles_AppSidebarMinimizerComponent = [];
var RenderType_AppSidebarMinimizerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSidebarMinimizerComponent, data: {} });
export { RenderType_AppSidebarMinimizerComponent as RenderType_AppSidebarMinimizerComponent };
export function View_AppSidebarMinimizerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["appBrandMinimizer", ""], ["appSidebarMinimizer", ""], ["class", "sidebar-minimizer"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).toggleOpen($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).toggleOpen($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.SidebarMinimizeDirective, [], null, null), i0.ɵdid(2, 16384, null, 0, i1.BrandMinimizeDirective, [], null, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
export function View_AppSidebarMinimizerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-minimizer", [], null, null, null, View_AppSidebarMinimizerComponent_0, RenderType_AppSidebarMinimizerComponent)), i0.ɵdid(1, 49152, null, 0, i2.AppSidebarMinimizerComponent, [], null, null)], null, null); }
var AppSidebarMinimizerComponentNgFactory = i0.ɵccf("app-sidebar-minimizer", i2.AppSidebarMinimizerComponent, View_AppSidebarMinimizerComponent_Host_0, {}, {}, []);
export { AppSidebarMinimizerComponentNgFactory as AppSidebarMinimizerComponentNgFactory };
