import * as tslib_1 from "tslib";
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { idb } from '../../core/storage/indexedDB';
import bcrypt from 'bcryptjs';
import { timeout, retry, catchError, share } from 'rxjs/operators';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/services/auth.service';
import { NavsNotifService } from '../../core/services/navs-notif.service';
import { SyncService } from '../../core/services/sync.service';
import { AppVariables } from '../../core/interfaces/app-variables';
import * as i0 from "@angular/core";
import * as i1 from "../../core/interfaces/app-variables";
import * as i2 from "../../core/services/auth.service";
import * as i3 from "../../core/services/api.service";
import * as i4 from "@angular/router";
import * as i5 from "ngx-toastr";
import * as i6 from "ngx-spinner";
import * as i7 from "@ng-idle/core/src/idle";
import * as i8 from "../../core/services/navs-notif.service";
import * as i9 from "@angular/common";
import * as i10 from "../../core/services/sync.service";
var LoginService = /** @class */ (function () {
    function LoginService(_app, _authService, _api, _router, _toast, _spin, _idle, _pushNofif, _location, syncSvc) {
        this._app = _app;
        this._authService = _authService;
        this._api = _api;
        this._router = _router;
        this._toast = _toast;
        this._spin = _spin;
        this._idle = _idle;
        this._pushNofif = _pushNofif;
        this._location = _location;
        this.syncSvc = syncSvc;
        this.fromIdleState = false;
        this.visible = false;
    }
    LoginService.prototype.authenticate = function (credential) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var username, password, local_user, login_date, authenticated, success, message_1, data, token_1, user, token, assign_branch, cash_drawer_open, modules, _id, message;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        username = credential.username, password = credential.password;
                        local_user = null;
                        login_date = this._authService.getDateString();
                        this._spin.show();
                        return [4 /*yield*/, this._api
                                ._post('/users/authenticate', credential)
                                .pipe(timeout(5000), retry(2), catchError(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.validateOfflineLogin(credential)];
                                        case 1:
                                            local_user = _a.sent();
                                            return [2 /*return*/, { offline: true, local_user: local_user }];
                                    }
                                });
                            }); }), share())
                                .toPromise()];
                    case 1:
                        authenticated = _a.sent();
                        this._spin.hide();
                        if (!authenticated.offline) {
                            success = authenticated.success, message_1 = authenticated.message, data = authenticated.data;
                            if (!success)
                                return [2 /*return*/, this._toast.error(message_1, 'Login Failed')];
                            token_1 = data.token, user = data.user;
                            local_user = tslib_1.__assign({}, user, { token: token_1, timestamp: Date.now(), message: message_1, login_date: login_date });
                        }
                        else {
                            local_user = tslib_1.__assign({}, authenticated.local_user, { login_date: login_date });
                            if (!authenticated.local_user)
                                return [2 /*return*/];
                        }
                        token = local_user.token, assign_branch = local_user.assign_branch, cash_drawer_open = local_user.cash_drawer_open, modules = local_user.modules, _id = local_user._id, message = local_user.message;
                        idb.logins.put(local_user);
                        // if (!assign_branch.enable_registry) local_user.modules.dashboard = false;
                        this._idle.watch();
                        this._authService.last_used_password = password;
                        this._authService.storeUserData(token, local_user);
                        this._pushNofif.loadNotif(local_user);
                        if (this.fromIdleState)
                            return [2 /*return*/, this._location.back()];
                        // if (this._app.sync === 0) this.syncSvc.init_inventories(assign_branch._id, true);
                        // if (!this._app.connected) return this._router.navigate(['/sales/invoice']);
                        if (!assign_branch.enable_registry) {
                            return [2 /*return*/, this._router.navigate(['/user', _id])];
                        }
                        if (cash_drawer_open) {
                            if (modules.dashboard)
                                return [2 /*return*/, this._router.navigate(['/dashboard'])];
                            this._router.navigate(['/user', _id]);
                        }
                        else {
                            this._toast.warning(message, null, { timeOut: 5000, progressAnimation: 'increasing' });
                            this._router.navigate(['/opening']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.validateOfflineLogin = function (credential) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var username, password, ZDateReading, idb_user, isMatch, assign_branch, registry, registries, status, message;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        username = credential.username, password = credential.password;
                        ZDateReading = this.getZReadDate();
                        return [4 /*yield*/, idb.logins.get({ username: username })];
                    case 1:
                        idb_user = _a.sent();
                        if (!idb_user) {
                            this._toast.error('User was not found in the local collection! Try establishing connection to the internet', 'Login Failed!', {
                                timeOut: 5000,
                            });
                            return [2 /*return*/, false];
                        }
                        isMatch = bcrypt.compareSync(password, idb_user.pincode);
                        if (!isMatch) {
                            this._toast.error('When offline use pincode instead', 'Pincode does not match!');
                            return [2 /*return*/, false];
                        }
                        assign_branch = idb_user.assign_branch;
                        return [4 /*yield*/, idb.dashboard.where(['ref_id+branch._id']).equals([ZDateReading, assign_branch._id]).first()];
                    case 2:
                        registry = _a.sent();
                        return [4 /*yield*/, idb.dashboard
                                .orderBy('ref_id', 'desc')
                                .filter(function (reg) {
                                return reg.ref_id != ZDateReading && reg.status == 'open' && reg.branch._id == assign_branch._id;
                            })
                                .toArray()];
                    case 3:
                        registries = _a.sent();
                        status = registry ? true : false;
                        message = '';
                        idb_user.cash_drawer_open = registries.length === 0 && status && registry.status == 'open';
                        idb_user.disable_opening = registries.length !== 0 || (status && registry.status == 'closed');
                        if (!idb_user.disable_opening && idb_user.cash_drawer_open) {
                            idb_user.cash_registry_id = registry._id;
                            message = 'Register is already open and successfully authenticated!';
                        }
                        else if (!idb_user.disable_opening && !idb_user.cash_drawer_open) {
                            idb_user.cash_registry_id = null;
                            idb_user.message = 'No open registry yet!';
                        }
                        else if (idb_user.disable_opening && !idb_user.cash_drawer_open) {
                            idb_user.cash_registry_id = null;
                            idb_user.message = "You've already closed the store, contact admin if you wanted to re-open today's registry";
                        }
                        else {
                            idb_user.cash_registry_id = null;
                            idb_user.message = 'You must close all open z-reading before you can start a new';
                        }
                        idb_user.timestamp = Date.now();
                        return [2 /*return*/, idb_user];
                }
            });
        });
    };
    LoginService.prototype.getZReadDate = function () {
        var ndate = new Date();
        var yyyy = ndate.getFullYear().toString();
        var mm = String(ndate.getMonth() + 1).padStart(2, '0');
        var dd = String(ndate.getDate()).padStart(2, '0');
        return yyyy + "-" + mm + "-" + dd;
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.AppVariables), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.ApiService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.ToastrService), i0.ɵɵinject(i6.NgxSpinnerService), i0.ɵɵinject(i7.Idle), i0.ɵɵinject(i8.NavsNotifService), i0.ɵɵinject(i9.LocationStrategy), i0.ɵɵinject(i10.SyncService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
